import { Box, Button, Card, Container, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import TableStyle from 'src/components/TableStyle';
import moment from 'moment';
import { commonUtils } from '../utils/utils';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardData } from '../redux/slice/dashboardSlice';
import { fetchPolicyData } from '../redux/slice/policySlice';
import { fetchUserData } from '../redux/slice/userSlice';
import { CiExport } from "react-icons/ci";

import {
  AppCurrentVisits,
  AppWebsiteVisits,
} from '../sections/@dashboard/app';
import Reminder from './Reminder/Reminders';


export default function AccountingPage() {
    const theme = useTheme();
    // const [userAction, setUserAction] = useState(null)
    const userData = useSelector((state) => state?.userDetails?.data)
    const { data, isLoading } = useSelector((state) => state?.policyDetails)
    const accountingdata = useSelector((state) => state?.dashboardDetails?.data)
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRowIds, setSelectedRowIds] = useState([]);

    function CustomToolbar() {    
      return (
        <GridToolbarContainer>
          <Box padding={"10px 0"}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector
              slotProps={{ tooltip: { title: 'Change density' } }}
            />
              </Box>
        </GridToolbarContainer>
      );
    }
    const columns = [
        {
          field: "policyNumber",
          headerName: "Policy Number",
          width: 300,
          cellClassName: "name-column--cell",
        },
        {
          field: "policyType",
          headerName: "Policy Type",
          width: 300,
        },
        {
          field: "insuredPersonName",
          headerName: "Insured",
          width: 300,
        },
        {
          field: "policyStartDate",
          headerName: "Policy Start Date",
          width: 300,
          valueFormatter: (params) => {
            const date = new Date(params.value);
            return date.toDateString();
          },
        },
        {
          field: "policyEndDate",
          headerName: "Policy End Date",
          width: 300,
          valueFormatter: (params) => {
            const date = new Date(params.value);
            return date.toDateString();
          },
        },
        {
          field: "policyStatus",
          headerName: "Policy Status",
          width: 300,
        },
        {
            field: "premiumAmount",
            headerName: "Premium",
            width: 300,
        },
        {
          field: "commission",
          headerName: "Commission Earned",
          width: 300,
        }
      ];
    
      const csvColumns = [
        { Header: "Policy Number", accessor: 'policyNumber' },
        { Header: "Policy Type", accessor: 'policyType' },
        { Header: "Insured", accessor: "insuredPersonName" },
        { Header: "Policy Start Date", accessor: 'policyStartDate', type: 'date' },
        { Header: "Policy End Date", accessor: 'policyEndDate', type: 'date' },
        { Header: "Policy Status", accessor: 'policyStatus' },
        { Header: "Premium", accessor: 'premiumAmount' },
        { Header: "Commission Earned", accessor: 'commission' },
      ];
      const handleClose = () => {
        setAnchorEl(null);
      };
      const downloadCsvOrExcel = async (extension, selectedIds) => {
        const formatDateOfBirth = (dateString, filednm) => {
          return moment(dateString).format('DD/MM/YYYY HH:MM A')
        };
    
        const formatRecords = (records) => {
          return records.map((rec) => {
            const selectedFieldsData = {};
            csvColumns?.forEach((item) => {
              if (item?.type === 'date') {
                selectedFieldsData[item?.accessor] = formatDateOfBirth(rec[item?.accessor]);
              }
              else {
                selectedFieldsData[item?.accessor] = rec[item?.accessor];
              }
            });
            return selectedFieldsData;
          });
        };
    
        if (selectedIds && selectedIds?.length > 0) {
          const selectedRecordsWithSpecificFileds = formatRecords(data?.filter((rec) => selectedIds?.includes(rec._id)));
          commonUtils.convertJsonToCsvOrExcel({ jsonArray: selectedRecordsWithSpecificFileds, csvColumns, fileName: "Policy", extension, setSelectedRowIds });
        } else {
          const AllRecordsWithSpecificFileds = formatRecords(data);
          commonUtils.convertJsonToCsvOrExcel({ jsonArray: AllRecordsWithSpecificFileds, csvColumns, fileName: "Policy", extension, setSelectedRowIds });
        }
      };
      const handleExportPolicy = (extension) => {
        if (selectedRowIds && selectedRowIds?.length > 0) {
          downloadCsvOrExcel(extension, selectedRowIds)
        } else {
          downloadCsvOrExcel(extension);
        }
      };

      useEffect(() => {
        if (userData?.length === 0) {
          dispatch(fetchUserData())
        }
        if (accountingdata?.length === 0) {
          dispatch(fetchDashboardData())
        }
        dispatch(fetchPolicyData())
      }, [])
      
    return (
        <>
          <Helmet>
            {/* <title> Dashboard | Minimal UI </title> */}
          </Helmet>
    
            <Container maxWidth>
            <Grid item xs={12} md={6} lg={8}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" >
                Policy
              </Typography>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <Button variant="contained" startIcon={<CiExport icon="eva:plus-fill" />} onClick={() => { handleClose(); handleExportPolicy('xlsx') }}>
                  {selectedRowIds && selectedRowIds?.length > 0 ? 'Export Selected Data' : 'Export'}
                </Button>
              </Stack>
            </Stack>
                  <TableStyle>
              <Box width="100%">
                {isLoading ? (
                  <Card style={{ display: 'flex', justifyContent: 'center', height: "600px" }}>
                    <span className="loader" />
                  </Card>
                ) : (
                  <Card style={{ height: "600px" }}>
                    <DataGrid
                      rows={data || []}
                      columns={columns}
                      components={{ Toolbar: () => CustomToolbar() }}
                      checkboxSelection
                      rowSelectionModel={selectedRowIds}
                      getRowId={row => row._id}
                    />
                  </Card>
                )}
              </Box>
            </TableStyle>
            </Grid>
            </Container>
            <Reminder/>
            </>
      );
}