import { Box, Button, Stack, Typography, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from "moment";
import { useEffect, useState } from 'react';
import Add from './AddPolicyNumber';
import { apidelete } from 'src/service/api';
import Edit from './EditPolicyNumber';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableStyleTwo from '../../TableStyleTwo';

// ----------------------------------------------------------------------


const PolicyNumbers = ({ rows, toggleVisibilityPolicyNumber, isVisiblePolicyNumber, _id, setUserAction }) => {

    const [policyNumber, setPolicyNumber] = useState({});
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const userRole = sessionStorage.getItem("userRole");

    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);
    const deletePolicyNumber = async (id) => {
        const result = await apidelete(`api/policynumber/delete/${id}`);
        setUserAction(result)
    }
    const columns = [
        {
            field: "PolicyNumber",
            headerName: "Certificate Number",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
        },
        {
            field: "TypeOfCertificate",
            headerName: "Certificate Type",
            flex: 1,
        },
        {
            field: "createdOn",
            headerName: "Created On",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {moment(params?.row?.createdOn).format('lll')}
                    </>
                );
            }
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box>
                        <Stack direction={"row"} spacing={2}>
                            {userRole !== "user" && (<Button variant='outlined' size='small' color='primary' onClick={() => {setPolicyNumber(params.row); handleOpenEdit();}}>Edit</Button>)}
                            {userRole !== "user" && (<Button variant='outlined' size='small' color='error' onClick={() => deletePolicyNumber(params.row._id)}>Delete</Button>)}
                        </Stack>
                    </Box>
                );
            }
          },
    ];

    return (
        <div>
            <Add open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} />
            <Edit open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} data={policyNumber}/>

            <Box style={{ cursor: "pointer" }} p={2}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityPolicyNumber}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisiblePolicyNumber ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AssignmentIcon />
                            <Typography variant="h5">Policy Numbers</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisiblePolicyNumber && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"

                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdd}
                                >
                                    Policy Number
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisiblePolicyNumber &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                        />
                    </Box>
                </TableStyleTwo>
            }
        </div>
    );
}

export default PolicyNumbers