import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CiExport } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import WAContactAdd from './AddContact';
import TableStyle from '../../../components/TableStyle';
import { fetchWAContactData } from '../../../redux/slice/WAcontactSlice';
import { commonUtils } from '../../../utils/utils';
import Iconify from '../../../components/iconify/Iconify';
import SendCampaign from '../SendCampaign';
import SendMessageTemplate from '../SendTemplateMsg';

// ----------------------------------------------------------------------


const WAContact = () => {

  const [policyList, setPolicyList] = useState([]);
  const [userAction, setUserAction] = useState(null)
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { data, isLoading } = useSelector((state) => state?.WAcontactDetails)
  const [openAdd, setOpenAdd] = useState(false);
  const [openSendCampaign, setOpenSendCampaign] = useState(false);
  const [openSendTemplateMsg, setOpenSendTemplateMsg] = useState(false);
  const handleCloseSendCampaign = () => {
    setOpenSendCampaign(false)
  }

  const handleOpenSendCampaign = () => {
    setOpenSendCampaign(true)
  }
  const handleCloseSendTemplateMsg = () => {
    setOpenSendTemplateMsg(false)
  }

  const handleOpenSendTemplateMsg = () => {
    setOpenSendTemplateMsg(true)
  }
  const handleCloseAdd = () => {
    setOpenAdd(false)
  }

  const handleOpenAdd = () => {
    setOpenAdd(true)
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };


  const columns = [
    {
      field: "id",
      headerName: "Contact ID",
      width: 300,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        const handleWAContactIDClick = () => {
          navigate(`/dashboard/whatsapp/contacts/${params.id}`)
        };

        return (
          <Box onClick={handleWAContactIDClick}>
            {params.value}
          </Box>
        );
      }
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 300,
    },
    {
      field: "created_at",
      headerName: "Created On",
      width: 300,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toDateString();
      },
    },
  ];

  const csvColumns = [
    { Header: "Contact ID", accessor: 'id' },
    { Header: "Name", accessor: 'name' },
    { Header: "Phone Number", accessor: 'phone' },
    { Header: "Created On", accessor: 'created_at', type: 'date'},
  ];

  const downloadCsvOrExcel = async (extension, selectedIds) => {
    const formatDateOfBirth = (dateString, filednm) => {
      return moment(dateString).format('DD/MM/YYYY HH:MM A')
    };

    const formatRecords = (records) => {
      return records.map((rec) => {
        const selectedFieldsData = {};
        csvColumns?.forEach((item) => {
          if (item?.type === 'date') {
            selectedFieldsData[item?.accessor] = formatDateOfBirth(rec[item?.accessor]);
          }
          else {
            selectedFieldsData[item?.accessor] = rec[item?.accessor];
          }
        });
        return selectedFieldsData;
      });
    };

    if (selectedIds && selectedIds?.length > 0) {
      const selectedRecordsWithSpecificFileds = formatRecords(data?.filter((rec) => selectedIds?.includes(rec._id)));
      commonUtils.convertJsonToCsvOrExcel({ jsonArray: selectedRecordsWithSpecificFileds, csvColumns, fileName: "Policy", extension, setSelectedRowIds });
    } else {
      const AllRecordsWithSpecificFileds = formatRecords(data);
      commonUtils.convertJsonToCsvOrExcel({ jsonArray: AllRecordsWithSpecificFileds, csvColumns, fileName: "Policy", extension, setSelectedRowIds });
    }
  };
  const handleExportPolicy = (extension) => {
    if (selectedRowIds && selectedRowIds?.length > 0) {
      downloadCsvOrExcel(extension, selectedRowIds)
    } else {
      downloadCsvOrExcel(extension);
    }
  };

  useEffect(() => {
    dispatch(fetchWAContactData());
  }, [userAction])

  return (
    <>
      {/* Add Lead Model */}
      <WAContactAdd open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
      <SendMessageTemplate open={openSendTemplateMsg} handleClose={handleCloseSendTemplateMsg} setUserAction={setUserAction} />
      <SendCampaign open={openSendCampaign} handleClose={handleCloseSendCampaign} setUserAction={setUserAction} />

      <Container maxWidth>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" >
            WhatsApp Contacts
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
              Add New
            </Button>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenSendTemplateMsg}>
              Send Message Template
            </Button>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenSendCampaign}>
              Send Campaign
            </Button>
            <Button variant="contained" startIcon={<CiExport icon="eva:plus-fill" />} onClick={() => { handleClose(); handleExportPolicy('xlsx') }}>
              {selectedRowIds && selectedRowIds?.length > 0 ? 'Export Selected Data' : 'Export'}
            </Button>
          </Stack>
        </Stack>
        <TableStyle>
          <Box width="100%">
            {isLoading ? (
              <Card style={{ display: 'flex', justifyContent: 'center', height: "600px" }}>
                <span className="loader" />
              </Card>
            ) : (
              <Card style={{ height: "600px" }}>
                <DataGrid
                  rows={data || []}
                  columns={columns}
                  checkboxSelection
                  onRowSelectionModelChange={handleSelectionChange}
                  rowSelectionModel={selectedRowIds}
                  getRowId={row => row.id}
                />
              </Card>
            )}


          </Box>
        </TableStyle>
      </Container>
    </>
  );
}

export default WAContact