/* eslint-disable react/prop-types */
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from '@mui/lab';
import { Autocomplete, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, MenuItem, OutlinedInput, Radio, RadioGroup, Rating, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from "yup";
import { policyTypeList } from '../../_mock/data';
import { apipost } from '../../service/api';
import Palette from '../../theme/palette';

const Add = (props) => {

  const { open, handleClose, contactData, setUserAction } = props
  const [isLoading, setIsLoading] = useState(false);

  const userid = sessionStorage.getItem('user_id');
  const userdata = JSON.parse(sessionStorage.getItem('user'));
  const userDetails = useSelector((state) => state?.userDetails?.data)
  // -----------  validationSchema
  const validationSchema = yup.object({
    ClientName: yup.string().required("Client Name is required"),
    DueDate: yup.date().required("Reminder Due Date is required"),
    phoneNumber: yup.string().matches(/^[0-9]*$/, 'Client Phone Number is invalid').required('Client Phone Number is required'),
    emailAddress: yup.string().email('Invalid email').required("Client Email is required"),
    policyType: yup.string().required("Policy Type is required"),
    insuranceReferenceNumber: yup.string().required("Reference Number is required"),
    Status: yup.string().required("Reminder Status is required"),
  });

  // -----------   initialValues
  const initialValues = {
    ClientName: "",
    policyType: "",
    DueDate: "",
    phoneNumber: "",
    emailAddress: "",
    insuranceReferenceNumber: "",
    Status: "",
    comments: "",
    createdBy: userid,
    agency_id: userdata?.agency_id
  };

  // add Lead api
  const addLead = async (values) => {
    setIsLoading(true);
    try {
      const data = values;
      const result = await apipost('api/reminder/add', data);
      setUserAction(result);

      if (result && result.status === 201) {
        formik.resetForm();
        handleClose();
      }
    } catch (error) {
      console.error('Error adding reminder:', error);
    }
    setIsLoading(false);
  }
  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      addLead(values)
    },
  });


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Add New</Typography>
          <Typography>
            <ClearIcon
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
                <Typography style={{ marginBottom: "15px" }} variant="h6">
                    Client Details
                </Typography>
                <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                >
                    <Grid item xs={12} sm={12} md={12}>
                    <FormLabel>Client Name <span style={{ color: "red" }}>*</span></FormLabel>
                    <TextField
                        id="ClientName"
                        name="ClientName"
                        label=""
                        size='small'
                        maxRows={10}
                        fullWidth
                        value={formik.values.ClientName}
                        onChange={formik.handleChange}
                        error={
                        formik.touched.ClientName &&
                        Boolean(formik.errors.ClientName)
                        }
                        helperText={
                        formik.touched.ClientName && formik.errors.ClientName
                        }
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                    <FormLabel>Phone Number <span style={{ color: "red" }}>*</span></FormLabel>
                    <TextField
                        id="phoneNumber"
                        name="phoneNumber"
                        size='small'
                        fullWidth
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                        }
                        helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                        }
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <FormLabel>Email <span style={{ color: "red" }}>*</span></FormLabel>
                    <TextField
                        id="emailAddress"
                        name="emailAddress"
                        label=""
                        size='small'
                        fullWidth
                        value={formik.values.emailAddress}
                        onChange={formik.handleChange}
                        error={
                        formik.touched.emailAddress &&
                        Boolean(formik.errors.emailAddress)
                        }
                        helperText={
                        formik.touched.emailAddress && formik.errors.emailAddress
                        }
                    />
                    </Grid>
                </Grid>
                <Typography style={{ marginBottom: "15px" }} variant="h6">
                    Policy Details
                </Typography>
                <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth>
                            <FormLabel>Policy Type <span style={{ color: "red" }}>*</span></FormLabel>
                            <Autocomplete
                                id="combo-box-demo"
                                options={policyTypeList}
                                getOptionLabel={(item) => item?.lable}
                                value={policyTypeList?.find((item) => item?.value === formik.values.policyType)}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue("policyType", newValue ? newValue?.value : "");
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        size="small"
                                        error={formik.touched.policyType && Boolean(formik.errors.policyType)}
                                        helperText={formik.touched.policyType && formik.errors.policyType}
                                        placeholder='Select'
                                    />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormLabel>Insurance Reference Number </FormLabel>
                        <TextField
                            name='insuranceReferenceNumber'
                            size='small'
                            fullWidth
                            value={formik.values.insuranceReferenceNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.insuranceReferenceNumber && Boolean(formik.errors.insuranceReferenceNumber)}
                            helperText={formik.touched.insuranceReferenceNumber && formik.errors.insuranceReferenceNumber}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginBottom: "15px" }} variant="h6">
                    Reminder Details
                </Typography>
                <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                >
                    <Grid item xs={12} sm={6} md={6}>
                    <FormLabel>Reminder Due Date<span style={{ color: "red" }}>*</span></FormLabel>
                    <TextField
                        name='DueDate'
                        type='date'
                        size='small'
                        fullWidth
                        value={formik.values.DueDate}
                        onChange={formik.handleChange}
                        error={formik.touched.DueDate && Boolean(formik.errors.DueDate)}
                        helperText={formik.touched.DueDate && formik.errors.DueDate}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth>
                            <FormLabel>Reminder Status <span style={{ color: "red" }}>*</span></FormLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="Status"
                                name="Status"
                                size='small'
                                fullWidth
                                value={formik.values.Status}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.Status &&
                                    Boolean(formik.errors.Status)
                                }
                                helperText={
                                    formik.touched.Status && formik.errors.Status
                                }
                            >
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="InActive">InActive </MenuItem>
                                <MenuItem value="Expired">Expired </MenuItem>
                                <MenuItem value="Canceled">Canceled </MenuItem>
                            </Select>
                            <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.Status && formik.errors.Status}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormLabel>Comments<span style={{ color: "red" }}>*</span></FormLabel>
                        <TextField
                            id="comments"
                            name="comments"
                            size="small"
                            rows={5}
                            multiline
                            fullWidth
                            value={formik.values.comments}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.comments &&
                                Boolean(formik.errors.comments)
                            }
                            helperText={
                                formik.touched.comments && formik.errors.comments
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContentText>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
            {isLoading ? <CircularProgress size={27} /> : 'Save'}
          </LoadingButton>
          <Button onClick={() => {
            formik.resetForm()
            handleClose()
          }} variant='outlined' color='error'>Cancle</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Add