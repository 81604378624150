/* eslint-disable no-undef */
import React, { } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
// routes
import { ToastContainer } from 'react-toastify';
import Routers from './routes';
import UserRoutes from './UserRouters'

// theme
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import LoginPage from './pages/LoginPage';
import ResetPassword from './sections/auth/forgotpassword/ResetPasswordForm';
import VerifyEmail from './sections/auth/forgotpassword/VerifyEmailForm';
import VerifyOTP from './sections/auth/forgotpassword/VerifyOTPForm';
import 'react-toastify/dist/ReactToastify.css';
import ThemeProvider from './theme';
import './assets/style.css'
import SubscribeAgency from './sections/auth/subscription/payment';
import GetAgencyCode from './sections/auth/forgotagencycode/VerifyEmailForm';
// ----------------------------------------------------------------------

export default function App() {

  const token = sessionStorage.getItem('token');

  // eslint-disable-next-line react-hooks/exhaustive-deps, prefer-const
  const user = JSON.parse(sessionStorage.getItem('user'))
  useNavigate()

  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <ToastContainer />
      {token && user?.role ? (
        user?.role === 'admin' ? <Routers /> : user?.role === 'user' ? <UserRoutes /> : undefined
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sendotp" element={<VerifyEmail />} />
          <Route path="/verifyotp" element={<VerifyOTP />} />
          <Route path="/resetpassword/:id" element={<ResetPassword />} />
          <Route path="/subscribe" element={<SubscribeAgency />} />
          <Route path="/forgotagencycode" element={<GetAgencyCode />} />
          <Route path="*" element={<Navigate to="/login" />} />

        </Routes>
      )}
    </ThemeProvider>
  );
}
