import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from '@mui/lab';
import { CircularProgress, FormControl, FormLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { fetchUnderwriterData } from "src/redux/slice/underwriterSlice";


const SubmitQuotation = (props) => {
  const { open, handleClose, setQuotationData } = props
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const underwriters = useSelector((state) => state?.underwriterDetails?.data) || [];

  // -----------  validationSchema
  const validationSchema = yup.object({
    file: yup.string().required("Image File is required"),
    carregistrationnumber: yup.string().required("Car Registration Number is required"),
    carvalue: yup.string().required("Car Value is required"),
    agencyName: yup.string().required('Agency Name is invalid'),
    mpesaPaybill: yup.string().required("Paybill is required"),
    trainingrate: yup.string().required('Training rate is required'),
    phcfrate: yup.string().required('PHFC Rate is required'),
    stampduty: yup.string().required('Stamp Duty is required'),
  });

  // -----------   initialValues
  const initialValues = {
    carregistrationnumber: "",
    carvalue: "",
    mpesaPaybill: "",
    agencyName: "",
    trainingrate: "0.25",
    phcfrate: "0.2",
    stampduty: "40",
    file: "",
  };

  const QuotationArithmetics = async(values) => {
    const results = []
    const carValue = Number(values.carvalue);
    const trainingRate = Number(values.trainingrate);
    const phcfRate = Number(values.phcfrate);
    const stampDuty = Number(values.stampduty);
    await underwriters.map((underwriter) => {
      const record = {}
      const expected_material_damage = (Number(underwriter.material_damage_excess_rate) * carValue) / 100
      const expected_pvt = (Number(underwriter.pvt_rate) * carValue) / 100
      const premium_record = underwriter.premium.find((premium) => premium.minimum_range <= carValue && carValue <= premium.maximum_range ) || {};
      const premium_rate = premium_record?.premium_rate || 0;
      const premium_minimum = premium_record?.premium_minimum || 0;
      const premium = (Number(premium_rate) * carValue) / 100;
      const material_damage = expected_material_damage >= Number(underwriter.material_damage_excess_minimum) ? expected_material_damage : underwriter.material_damage_excess_minimum;
      const pvt = expected_pvt >= Number(underwriter.pvt_minimum) ? expected_pvt : Number(underwriter.pvt_minimum);
      const total_premium = premium + material_damage + pvt;
      const training_levy = (trainingRate * total_premium) / 100;
      const phcf_levy = (phcfRate * total_premium) / 100;
      const total_after_taxes = total_premium + training_levy + phcf_levy + stampDuty;
      record.underwriter = underwriter.underwriterName
      record.premium = premium >= premium_minimum ? premium : premium_minimum;
      record.material_damage = material_damage;
      record.pvt = pvt;
      record.total_premium = total_premium;
      record.training_levy = training_levy;
      record.phcf_levy = phcf_levy;
      record.stamp_duty = stampDuty;
      record.total_after_taxes = total_after_taxes;
      results.push(record)
    });
    return {values, results};
  }

  const submitQuotation = async (values) => {
    setIsLoading(true);
    const res = await QuotationArithmetics(values)
    await setQuotationData(res);
    setIsLoading(false);
    formik.resetForm();
    handleClose();
  }
  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      submitQuotation(values)
    },
  });

  useEffect(() => {
    dispatch(fetchUnderwriterData())
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Submit Quotation</Typography>
          <Typography>
            <ClearIcon
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form encType="multipart/form-data">
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Car Registration Number <span style={{ color: "red" }}>*</span></FormLabel>
                  <TextField
                    id="carregistrationnumber"
                    name="carregistrationnumber"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.carregistrationnumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.carregistrationnumber &&
                      Boolean(formik.errors.carregistrationnumber)
                    }
                    helperText={
                      formik.touched.carregistrationnumber && formik.errors.carregistrationnumber
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Car Value <span style={{ color: "red" }}>*</span></FormLabel>
                  <TextField
                    id="carvalue"
                    name="carvalue"
                    label=""
                    size='small'
                    type="number"
                    fullWidth
                    value={formik.values.carvalue}
                    onChange={formik.handleChange}
                    error={formik.touched.carvalue && Boolean(formik.errors.carvalue)}
                    helperText={formik.touched.carvalue && formik.errors.carvalue}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Agency Name</FormLabel>
                  <TextField
                    id="agencyName"
                    name="agencyName"
                    size='small'
                    fullWidth
                    value={formik.values.agencyName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.agencyName &&
                      Boolean(formik.errors.agencyName)
                    }
                    helperText={
                      formik.touched.agencyName && formik.errors.agencyName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormLabel>Upload Agency Logo<span style={{ color: "red" }}>*</span></FormLabel>
                    <TextField
                        id="file"
                        name="file"
                        size="small"
                        maxRows={10}
                        fullWidth
                        type="file"
                        multiple
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => {
                          if(event.currentTarget.files.length > 0){
                            const file = event.currentTarget.files[0];
                            switch(file.type){
                              case 'image/png':
                                formik.setFieldValue("file", file);
                                break;
                              case 'image/jpg':
                                formik.setFieldValue("file", file);
                                break;
                              case 'image/jpeg':
                                formik.setFieldValue("file", file);
                                break;
                              default:
                            }
                          }
                        }}
                        error={
                            formik.touched.file &&
                            Boolean(formik.errors.file)
                        }
                        helperText={
                            formik.touched.file && formik.errors.file
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Training Levy Rate <span style={{ color: "red" }}>*</span></FormLabel>
                  <TextField
                    id="trainingrate"
                    name="trainingrate"
                    type="number"
                    size='small'
                    fullWidth
                    value={formik.values.trainingrate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.trainingrate &&
                      Boolean(formik.errors.trainingrate)
                    }
                    helperText={
                      formik.touched.trainingrate && formik.errors.trainingrate
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>PHCF Levy Rate <span style={{ color: "red" }}>*</span></FormLabel>
                  <TextField
                    id="phcfrate"
                    name="phcfrate"
                    type="number"
                    size='small'
                    fullWidth
                    value={formik.values.phcfrate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phcfrate &&
                      Boolean(formik.errors.phcfrate)
                    }
                    helperText={
                      formik.touched.phcfrate && formik.errors.phcfrate
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Stamp Duty <span style={{ color: "red" }}>*</span></FormLabel>
                  <TextField
                    id="stampduty"
                    name="stampduty"
                    type="number"
                    size='small'
                    fullWidth
                    value={formik.values.stampduty}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.stampduty &&
                      Boolean(formik.errors.stampduty)
                    }
                    helperText={
                      formik.touched.stampduty && formik.errors.stampduty
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>MPESA Paybill <span style={{ color: "red" }}>*</span></FormLabel>
                  <TextField
                    id="mpesaPaybill"
                    name="mpesaPaybill"
                    label=""
                    size='small'
                    fullWidth
                    value={formik.values.mpesaPaybill}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mpesaPaybill &&
                      Boolean(formik.errors.mpesaPaybill)
                    }
                    helperText={
                      formik.touched.mpesaPaybill && formik.errors.mpesaPaybill
                    }
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
            {isLoading ? <CircularProgress size={27} /> : 'Submit'}
          </LoadingButton>
          <Button onClick={() => {
            formik.resetForm()
            handleClose()
          }} variant='outlined' color='error'>Cancle</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubmitQuotation;