// import { Container, Stack, Grid } from "@mui/material";
import moment from 'moment'
import {Document, Text, Page, View, Image, StyleSheet, Font} from '@react-pdf/renderer';

// Font.register({
//     family: 'Open Sans',
//     src: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap',
// });


const styles = StyleSheet.create({
    page: {
      display: "flex",
      backgroundColor: '#E4E4E4',
      fontSize: '12px',
    //   fontFamily: 'Open Sans',
    },
    horizontalView: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '300px',
    },
    verticalView: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textHeader: {
        fontWeight: "bold",
    },
    logo: {
        height: '100px',
        width: '100px',
        marginTop: '20px',
        border: '1px solid black',
        borderRadius: '5px',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
        display: 'table',
        width: 'auto',
        margin: '10px 0',
        borderSpacing: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCell: {
        margin: 'auto',
        padding: 5,
        border: '1px solid #000',
        width: '20%',
        textAlign: 'center',
    }
  });



const InvoiceTemplate = ({data}) => {
    return (
        <Document>
            {Object.keys(data).length > 0 ?
                <Page size="A4" style={styles.page}>
                    <View style={styles.verticalView}>
                        <Image style={styles.logo} src={URL.createObjectURL(data?.file)}/>
                        <Text style={styles.textHeader}>SALES INVOICE</Text>
                        <Text>Invoice Number: {`${data?.InvoiceNumber}`}</Text>
                        <Text>Invoice Date: {moment(data?.createdOn).format('lll')}</Text>
                    </View>
                    <View style={styles.horizontalView}>
                        <View>
                            <Text>INVOICE FROM</Text>
                            <Text>From: {`${data?.agencyName}`}</Text>
                            <Text>KRA Pin: {`${data?.agencyKRAPin}`}</Text>
                            <Text>Email: {`${data?.agencyEmail}`}</Text>
                            <Text>Contact: {`${data?.agencyContact}`}</Text>
                        </View>
                        <View>
                            <Text>INVOICE TO</Text>
                            <Text>To: {`${data?.CustomerName}`}</Text>
                            <Text>KRA Pin: {`${data?.kraPin}`}</Text>
                            <Text>Email: {`${data?.email}`}</Text>
                            <Text>Contact: {`${data?.phoneNumber}`}</Text>
                        </View>
                    </View>
                    {/* Table */}
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>ITEM</Text>
                            <Text style={styles.tableCell}>DESCRIPTION</Text>
                            <Text style={styles.tableCell}>PRICE</Text>
                            <Text style={styles.tableCell}>QUANTITY</Text>
                            <Text style={styles.tableCell}>TOTAL</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>{`${data?.product || ""}`}</Text>
                            <Text style={styles.tableCell}>{`${data?.description || ""}`}</Text>
                            <Text style={styles.tableCell}>{`${data?.unit_cost || ""}`}</Text>
                            <Text style={styles.tableCell}>{`${data?.quantity || ""}`}</Text>
                            <Text style={styles.tableCell}>{`${(Number(data?.unit_cost) * Number(data?.quantity)) || ""}`}</Text>
                        </View>
                    </View>
                </Page>: <Page><View><Text>INVALID INVOICE</Text></View></Page>}
        </Document>
    );
}

export default InvoiceTemplate;

            {/* <div>
                 <Container>
            {Object.keys(data).length > 0 ?
            <Stack padding={20} fontFamily={'"Times New Roman", "Times", "serif"'}>
                SALES INVOICE
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    {data?.file ? <img src={URL.createObjectURL(data?.file)} className="agencyLogo" alt="Agency Logo" /> : null}
                    {`${data?.agencyName}`}
                </Stack>
                <Grid container display="flex" spacing={4}>
                    <Grid item xs={12} sm={6}>
                        INVOICE FROM:
                        From: {`${data?.agencyName}`}
                        KRA Pin: {`${data?.agencyName}`}
                        Email: {`${data?.agencyEmail}`}
                        Contact: {`${data?.agencyContact}`}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        INVOICE TO:
                        To: {`${data?.CustomerName}`}
                        KRA Pin: {`${data?.kraPin}`}
                        Email: {`${data?.email}`}
                        Contact: {`${data?.phoneNumber}`}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        INVOICE DETAILS
                        Invoice Number: {`${data?.InvoiceNumber}`}
                        Invoice Date: {moment(data?.createdOn).format('lll')}
                    </Grid>
                </Grid>
                <table>
                <tr>
                    <td>ITEM</td>
                    <td>DESCRIPTION</td>
                    <td>PRICE</td>
                    <td>QUANTITY</td>
                    <td>TOTAL</td>
                </tr>
                <tbody>
                    <tr>
                        <td>{`${data?.product || ""}`}</td>
                        <td>{`${data?.description || ""}`}</td>
                        <td>{`${data?.unit_cost || ""}`}</td>
                        <td>{`${data?.quantity || ""}`}</td>
                        <td>{`${(Number(data?.unit_cost) * Number(data?.quantity)) || ""}`}</td>
                    </tr>
                </tbody>
                </table>
            </Stack> : "INVALID INVOICE" }
            </Container>
        </div> */}