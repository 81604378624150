import { useEffect, useState } from 'react';
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, TextField, ListItemText} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { apipost } from '../../service/api';
import { cancellationReasons } from 'src/_mock/CertificateCancelReasons';

const Send = (props) => {
    const { open, handleClose, setUserAction, id} = props
    const [isLoading, setIsLoading] = useState(false);
    // -----------  validationSchema
    const validationSchema = yup.object({
        cancelreasonid: yup.string().required('Cancellation Reason is required'),
    });

    // -----------   initialValues
    const initialValues = {
        cancelreasonid: "",
    };

    // add policy api
    const cancelCertificate = async (values) => {
        setIsLoading(true);
        try {
            const data = {
                cancelreasonid: values.cancelreasonid,
            }
            const result = await apipost(`api/dmvic/cancelcertificates/${id}`, data)

            setUserAction(result)

            if (result && result.status >= 200 && result.status < 300) {
                formik.resetForm();
                handleClose();
            }
        } catch (error) {
            console.error('Error cancelling certificate:', error);
        }
        setIsLoading(false);
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            cancelCertificate(values)
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Cancel Certificate </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <FormLabel>Reason for Cancellation</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="cancelreasonid"
                                        name="cancelreasonid"
                                        value={formik.values.cancelreasonid}
                                        onChange={formik.handleChange}
                                        error={formik.touched.cancelreasonid && Boolean(formik.errors.cancelreasonid)}
                                    >
                                        {
                                            cancellationReasons?.map((reason) =>{ 
                                                return (
                                                    <MenuItem value={reason?.value}>
                                                        {`${reason?.label}`}
                                                    </MenuItem>
                                                )}
                                            )
                                        }
                                    </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.cancelreasonid && Boolean(formik.errors.cancelreasonid)
                                            }
                                        >
                                        {formik.touched.cancelreasonid && formik.errors.cancelreasonid}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button onClick={() => {
                        formik.resetForm()
                        handleClose()
                    }} variant='outlined' color='error'>Cancle</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Send;