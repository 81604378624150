import { Box, Button, Card, Container, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SubmitQuotation from './submit';
import { usePDF } from 'react-to-pdf';
import './quotation.css';

const GenerateQuotation = () => {
    const [quotationData, setQuotationData] = useState({});
    const [openQuotation, setOpenQuotation] = useState(false);
    const handleCloseQuotation = () => setOpenQuotation(false)
    const handleOpenQuotation = () => setOpenQuotation(true);
    const { toPDF, targetRef } = usePDF({filename: 'quotation.pdf'});
    return (
    <>
      <SubmitQuotation open={openQuotation} handleClose={handleCloseQuotation} setQuotationData={setQuotationData}/>
      <Container maxWidth>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">
            Generate Quotation
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Button variant="contained" onClick={handleOpenQuotation}>Submit Quotation</Button>
            <Button variant="contained" onClick={toPDF}>Get Quotation</Button>
          </Stack>
        </Stack>
        {
          Object.keys(quotationData).length > 0 ? 
          <div ref={targetRef}>
            <Stack padding={20} fontFamily={'"Times New Roman", "Times", "serif"'}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                {quotationData?.values?.file ? <img src={URL.createObjectURL(quotationData?.values?.file )} className="agencyLogo" alt="Agency Logo" /> : null}
                <p>
                Call/ WhatsApp to get started +254 700 222 219 | 020 840 0 601<br/>
                Email: info@anzianoinsuranceagency.com<br/>
                Website: www.anzianoinsuranceagency.com<br/>
                Njengi Hse, Tom Mboya Street, Nairobi, 2nd Floor RM 3
                </p>
              </Stack>
              <p>Dear Client,<br/>Thank you for considering our agency as your preferred agency and intermediary for insurance policy.
              Below is summarissed analysis quotes comparison from different underwriters under our panel for your review for cost cost comparison.
              We will take you through each quote presentation for you to make a choice based on various factors other than the premiums payable.
              </p>
              <table>
                <tr>
                  <td>INSURED ITEM</td>
                  <td>COST CATEGORIES</td>
                  <td colspan={`${(quotationData?.results || []).length}`}>UNDERWRITER COSTS</td>
                </tr>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    {
                      (quotationData?.results || []).map((item)=>{
                        return (
                          <td>{`${item.underwriter}`}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    <td rowspan="9">{`${quotationData?.values?.carregistrationnumber || "--"}`}</td>
                    <td>Sum Insured</td>
                    <td colspan={`${(quotationData?.results || []).length}`}>{`${parseFloat((quotationData?.values?.carvalue || 0).toFixed(2)).toLocaleString()}`}</td>
                  </tr>
                  <tr>
                    <td>Premium</td>
                    {
                      (quotationData?.results || []).map((item)=>{
                        return (
                          <td>{`${parseFloat((item.premium || 0).toFixed(2)).toLocaleString()}`}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    <td>Material Damage Excess</td>
                    {
                      (quotationData?.results || []).map((item)=>{
                        return (
                          <td>{`${parseFloat((item.material_damage || 0).toFixed(2)).toLocaleString()}`}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    <td>PVT Excess</td>
                    {
                      (quotationData?.results || []).map((item)=>{
                        return (
                          <td>{`${parseFloat((item.pvt || 0).toFixed(2)).toLocaleString()}`}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    <td>Total Before Levies</td>
                    {
                      (quotationData?.results || []).map((item)=>{
                        return (
                          <td>{`${parseFloat((item.total_premium || 0).toFixed(2)).toLocaleString()}`}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    <td>PHCF Levy</td>
                    {
                      (quotationData?.results || []).map((item)=>{
                        return (
                          <td>{`${parseFloat((item.phcf_levy || 0).toFixed(2)).toLocaleString()}`}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    <td>Training Levy</td>
                    {
                      (quotationData?.results || []).map((item)=>{
                        return (
                          <td>{`${parseFloat((item.training_levy || 0).toFixed(2)).toLocaleString()}`}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    <td>Stamp Duty</td>
                    {
                      (quotationData?.results || []).map((item)=>{
                        return (
                          <td>{`${parseFloat((item.stamp_duty || 0).toFixed(2)).toLocaleString()}`}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    <td>TOTAL(Ksh)</td>
                    {
                      (quotationData?.results || []).map((item)=>{
                        return (
                          <td>{`${parseFloat((item.total_after_taxes || 0).toFixed(2)).toLocaleString()}`}</td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
              <p>
              Regards.<br/>
              Mpesa Paybill: {quotationData?.values?.mpesaPaybill || "--"}<br/>
              Paybill Name: Anziano Insurance Agency<br/>
              Account Name: "Your Name" or "Vehicle Registration Number"
              </p>
            </Stack>
            
          </div> : null
        }
      </Container>
    </>
  );
}

export default GenerateQuotation;