import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import { Box, Button, Card, Container, Stack, Typography, styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { CiExport, CiImport } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteModel from '../../components/Deletemodle';
import ImportModel from '../../components/Import/ImportModel';
import TableStyle from '../../components/TableStyle';
import Iconify from '../../components/iconify';
import { apiget, deleteManyApi } from '../../service/api';
import { commonUtils } from '../../utils/utils';
import AddInvoice from './Add';
import EditModel from './Edit';

// ----------------------------------------------------------------------

const StyledMenu = styled((props) => (
  <Menu
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));



function CustomToolbar({ selectedRowIds }, userRole) {
  const [opendelete, setOpendelete] = useState(false);
  const [userAction, setUserAction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleCloseDelete = () => setOpendelete(false)

  const handleOpenDelete = () => setOpendelete(true)


  const deleteManyInvoices = async (data) => {
    const result = await deleteManyApi('api/krainvoice/deletemany', data)
    setUserAction(result)
    handleCloseDelete();
  }


  useEffect(() => {
    setUserAction(userAction)
  }, [userAction])

  return (
    <GridToolbarContainer>
      <Box padding={"10px 0"}>

        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        {userRole !== "user" && (selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize', fontSize: "13", padding: "4px 5px 2px  0", marginRight: "3px" }} startIcon={<DeleteIcon style={{ fontSize: '19px', marginLeft: "8px", marginBottom: "2px" }} />} onClick={handleOpenDelete}>Delete</Button>)}
      </Box>
      <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyInvoices} id={selectedRowIds} />
    </GridToolbarContainer>
  );
}

const Invoice = () => {


  const [userAction, setUserAction] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [Invoices, setInvoices] = useState([])
  const [invoiceData, setInvoiceData] = useState({})
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openImpt, setOpenImpt] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const userid = sessionStorage.getItem('user_id');
  const userRole = sessionStorage.getItem("userRole")

  const { data, isLoading } = useSelector((state) => state?.leadDetails)

  const fieldsInCrm = [
    { Header: "Customer Name", accessor: 'CustomerName', type: 'string', required: true },
    { Header: "KRA Pin", accessor: 'kraPin', type: 'string', required: true },
    { Header: "Phone Number", accessor: 'phoneNumber', type: 'string' },
    { Header: "Email Address", accessor: 'email', type: 'string', required: true },
    { Header: "Product", accessor: 'product', type: 'string', required: true },
    { Header: "Unit Cost", accessor: 'unit_cost', type: 'string', required: true },
    { Header: "Quantity", accessor: 'quantity', type: 'string', required: true },
    { Header: "Create Date", accessor: 'createdOn', type: 'date', isDisplay: false, defVal: new Date() },
    { Header: "Create By", accessor: 'createdBy', type: 'string', isDisplay: false, defVal: userid, required: true },
    { Header: "Deleted", accessor: 'deleted', type: 'boolean', isDisplay: false, defVal: false },
  ];

  const csvColumns = [
    { Header: "Customer Name", accessor: 'CustomerName' },
    { Header: "KRA Pin", accessor: 'kraPin' },
    { Header: "Phone Number", accessor: 'phoneNumber' },
    { Header: "Email Address", accessor: 'email' },
    { Header: "Product", accessor: 'product' },
    { Header: "Unit Cost", accessor: 'unit_cost' },
    { Header: "Quantity", accessor: 'quantity' },
    { Header: "Create Date", accessor: 'createdOn' },
  ];

  // open edit model
  const handleOpenEdit = () => setOpenEdit(true);;
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const handleFirstNameClick = (id) => {
    navigate(`/dashboard/krainvoices/view/${id}`)
  };
  const handleOpenImpt = () => {
    setOpenImpt(true);
    handleClose()
  };
  const handleCloseImpt = () => setOpenImpt(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const fetchInvoices = async () => {
    const result = await apiget('api/krainvoice/');
    if (result && result.status === 200) {
      setInvoices(result.data?.result);
    }
    else
      setInvoices([])
  }
  const columns = [
    {
      field: "CustomerName",
      headerName: "Customer Name",
      width: 250,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        return (
          <Box onClick={() => handleFirstNameClick(params?.row?._id)}>
            {params.value}
          </Box>
        );
      }
    },
    {
      field: "kraPin",
      headerName: "KRA Pin",
      width: 250,
      cellClassName: "name-column--cell--capitalize",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 250,
    },
    {
      field: 'product',
      headerName: 'Product',
      width: 270,
    },
    {
        field: 'unit_cost',
        headerName: 'Unit Cost',
        width: 270,
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        width: 270,
    },
    {
      field: "createdOn",
      headerName: "Create Date",
      width: 270,
      renderCell: (params) => {
        return (
          <>
            {moment(params?.row?.createdOn).format('lll')}
          </>
        );
      }
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const handleInvoiceClick = async (data) => {
          setInvoiceData(data)
          handleOpenEdit();
        };
        return (
          <>
            <Button variant='text' size='small' color='primary' onClick={() => handleInvoiceClick(params?.row)}><EditIcon /></Button>
          </>
        );
      }
    },
  ];


  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  const downloadCsvOrExcel = async (extension, selectedIds) => {
    const formatDateOfBirth = (dateString, filednm) => {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      if (filednm === "dateOfBirth") {
        return moment(dateString).format('DD/MM/YYYY')
      }
      return moment(dateString).format('DD/MM/YYYY HH:MM A')

    };

    const formatRecords = (records) => {
      return records.map((rec) => {
        const selectedFieldsData = {};
        csvColumns?.forEach((item) => {
          if (item?.accessor === 'dateOfBirth') {
            selectedFieldsData[item?.accessor] = formatDateOfBirth(rec[item?.accessor], "dateOfBirth");
          } else if (item?.accessor === 'createdOn') {
            selectedFieldsData[item?.accessor] = formatDateOfBirth(rec[item?.accessor], "createdOn");
          }
          else {
            selectedFieldsData[item?.accessor] = rec[item?.accessor];
          }
        });
        return selectedFieldsData;
      });
    };

    if (selectedIds && selectedIds?.length > 0) {
      const selectedRecordsWithSpecificFileds = formatRecords(data?.filter((rec) => selectedIds?.includes(rec._id)));
      commonUtils.convertJsonToCsvOrExcel({ jsonArray: selectedRecordsWithSpecificFileds, csvColumns, fileName: "Invoice", extension, setSelectedRowIds });
    } else {
      const AllRecordsWithSpecificFileds = formatRecords(data);
      commonUtils.convertJsonToCsvOrExcel({ jsonArray: AllRecordsWithSpecificFileds, csvColumns, fileName: "Invoice", extension, setSelectedRowIds });
    }
  };

  const handleExportLeads = (extension) => {
    if (selectedRowIds && selectedRowIds.length > 0) {
      downloadCsvOrExcel(extension, selectedRowIds);
    } else {
      downloadCsvOrExcel(extension);
    }
  };


  useEffect(() => {
    fetchInvoices()
  }, [userAction])
  return (
    <>
      {/* Add Invoice Model */}
      <AddInvoice open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
      {/* Edit Invoice Model */}
      <EditModel open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} invoiceData={invoiceData} />

      <ImportModel open={openImpt} handleClose={handleCloseImpt} moduleName="Leads" api="api/lead/addMany" back="/dashboard/lead" fieldsInCrm={fieldsInCrm} />

      <Container maxWidth>
        <Stack direction="row" alignItems="center" mb={5} justifyContent={"space-between"}>
          <Typography variant="h4" >
            Invoices
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
              Add New
            </Button>
            <div>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Action
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleOpenImpt} disableRipple>
                  <CiImport style={{ marginRight: "7px" }} />
                  Import
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); handleExportLeads('xlsx') }} disableRipple>
                  <CiExport style={{ marginRight: "7px" }} />
                  {selectedRowIds && selectedRowIds?.length > 0 ? 'Export Selected Data' : 'Export'}
                </MenuItem>
              </StyledMenu>
            </div>
          </Stack>
        </Stack>
        <TableStyle>
          <Box width="100%">
            {isLoading ? (
              <Card style={{ display: 'flex', justifyContent: 'center', height: "600px" }}>
                <span className="loader" />
              </Card>
            ) : (
              <Card style={{ height: "600px" }} className='tableWraper'>
                <DataGrid
                  rows={Invoices || []}
                  columns={columns.map((column, index) => ({
                    ...column,
                    disableColumnMenu: index === columns.length - 1 // Disable menu icon for the last column
                  }))}
                  components={{ Toolbar: () => CustomToolbar({ selectedRowIds }, userRole) }}
                  checkboxSelection
                  onRowSelectionModelChange={handleSelectionChange}
                  rowSelectionModel={selectedRowIds}
                  getRowId={row => row._id}
                />

              </Card>
            )}

          </Box>
        </TableStyle>
      </Container>
    </>
  );
}

export default Invoice