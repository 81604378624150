import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Card, Container, Stack, Typography, styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { CiExport, CiImport } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteModel from '../../components/Deletemodle';
import TableStyle from '../../components/TableStyle';
import Iconify from '../../components/iconify';
import { fetchReminderData } from 'src/redux/slice/reminderSlice';
import { deleteManyApi } from '../../service/api';
import AddReminder from './Add';
import EditModel from './Edit';

// ----------------------------------------------------------------------

const StyledMenu = styled((props) => (
  <Menu
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));

function CustomToolbar({ selectedRowIds }, userRole) {
  const [opendelete, setOpendelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [userAction, setUserAction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleCloseEdit = () => setOpenEdit(false)

  const handleOpenEdit = () => setOpenEdit(true)

  const handleCloseDelete = () => setOpendelete(false)

  const handleOpenDelete = () => setOpendelete(true)


  const deleteManyLead = async (data) => {
    const result = await deleteManyApi('api/reminder/deletemany', data)
    dispatch(fetchReminderData())
    setUserAction(result)
    handleCloseDelete();
  }


  useEffect(() => {
    setUserAction(userAction)
  }, [userAction])

  return (
    <GridToolbarContainer>
      <Box padding={"10px 0"}>

        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />

        {userRole !== "user" && (selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize', fontSize: "13", padding: "4px 5px 2px  0", marginRight: "3px" }} startIcon={<DeleteIcon style={{ fontSize: '19px', marginLeft: "8px", marginBottom: "2px" }} />} onClick={handleOpenDelete}>Delete</Button>)}
      </Box>
      <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyLead} id={selectedRowIds} />
    </GridToolbarContainer>
  );
}

const Reminder = () => {


  const [userAction, setUserAction] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [reminderData, setReminderData] = useState({})
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openImpt, setOpenImpt] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const userid = sessionStorage.getItem('user_id');
  const userRole = sessionStorage.getItem("userRole")

  const { data, isLoading } = useSelector((state) => state?.reminderDetails)


  // open edit model
  const handleOpenEdit = () => setOpenEdit(true);;
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const handleFirstNameClick = (id) => {
    navigate(`/dashboard/reminder/view/${id}`)
  };
  const handleOpenImpt = () => {
    setOpenImpt(true);
    handleClose()
  };
  const handleCloseImpt = () => setOpenImpt(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      field: "ClientName",
      headerName: "Client Name",
      width: 250,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        return (
          <Box onClick={() => handleFirstNameClick(params?.row?._id)}>
            {params.value}
          </Box>
        );
      }
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: 250,
    },
    {
      field: 'policyType',
      headerName: 'Policy',
      width: 270,
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: 270,
    },
    {
        field: 'DueDate',
        headerName: 'Due Date',
        width: 270,
        valueFormatter: (params) => {
          const date = new Date(params.value);
          return date.toDateString();
        },
    },
    {
      field: "createdOn",
      headerName: "Create Date",
      width: 270,
      renderCell: (params) => {
        return (
          <>
            {moment(params?.row?.createdOn).format('lll')}
          </>
        );
      }
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const handleFirstNameClick = async (data) => {
          setReminderData(data)
          handleOpenEdit();
        };
        return (
          <>
            <Button variant='text' size='small' color='primary' onClick={() => handleFirstNameClick(params?.row)}><EditIcon /></Button>
          </>
        );
      }
    },
  ];


  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };




  useEffect(() => {
    dispatch(fetchReminderData())
  }, [userAction])
  return (
    <>
      {/* Add Reminder Model */}
      <AddReminder open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
      {/* Edit Reminder Model */}
      <EditModel open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} reminderData={reminderData} />

      <Container maxWidth>
        <Stack direction="row" alignItems="center" mb={5} justifyContent={"space-between"}>
          <Typography variant="h4" >
            Reminder
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
              Add New
            </Button>
            <div>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Action
              </Button>
            </div>
          </Stack>
        </Stack>
        <TableStyle>
          <Box width="100%">
            {isLoading ? (
              <Card style={{ display: 'flex', justifyContent: 'center', height: "600px" }}>
                <span className="loader" />
              </Card>
            ) : (
              <Card style={{ height: "600px" }} className='tableWraper'>
                <DataGrid
                  rows={data || []}
                  columns={columns.map((column, index) => ({
                    ...column,
                    disableColumnMenu: index === columns.length - 1 // Disable menu icon for the last column
                  }))}
                  components={{ Toolbar: () => CustomToolbar({ selectedRowIds }, userRole) }}
                  checkboxSelection
                  onRowSelectionModelChange={handleSelectionChange}
                  rowSelectionModel={selectedRowIds}
                  getRowId={row => row._id}
                />

              </Card>
            )}

          </Box>
        </TableStyle>
      </Container>
    </>
  );
}

export default Reminder