/* eslint-disable react/prop-types */
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
// eslint-disable-next-line import/no-unresolved
import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormLabel } from "@mui/material";
import { apipost } from "../../service/api";

const Add = (props) => {
    const { open, handleClose, setUserAction } = props
    const [isLoading, setIsLoading] = useState(false);

    // -----------  validationSchema
    const validationSchema = yup.object({
        underwriterName: yup.string().required("Underwriter is required"),
        CompanyID: yup.string().required("Member Company ID is required"),
        material_damage_excess_rate: yup.string().required("Material Damage Rate is required"),
        material_damage_excess_minimum: yup.string().required("Material Damage minimum is required"),
        pvt_rate: yup.string().required("PVT Rate is required"),
        pvt_minimum: yup.string().required("PVT minimum is required"),
    });

    // -----------   initialValues
    const initialValues = {
        underwriterName: "",
        CompanyID: "",
        material_damage_excess_rate: "",
        material_damage_excess_minimum: "",
        pvt_rate: "",
        pvt_minimum: "",
    };

    // add email api
    const addUnderwriter = async (values) => {
        setIsLoading(true)

        try {
            const data = values;
            const result = await apipost('api/underwriter/add', data)
            setUserAction(result)
        } catch (error) {
            console.log(error);
        }
        handleClose();
        formik.resetForm();
        setIsLoading(false)
    }
    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {
            addUnderwriter(values);
            resetForm();
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Add Underwriter / Member Company</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={12}>
                                    <FormLabel>Underwriter Name</FormLabel>
                                    <TextField
                                        id="underwriterName"
                                        name="underwriterName"
                                        size="small"
                                        fullWidth
                                        value={formik.values.underwriterName}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.underwriterName &&
                                            Boolean(formik.errors.underwriterName)
                                        }
                                        helperText={
                                            formik.touched.underwriterName && formik.errors.underwriterName
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormLabel>Member Company ID</FormLabel>
                                    <TextField
                                        id="CompanyID"
                                        name="CompanyID"
                                        size="small"
                                        fullWidth
                                        value={formik.values.CompanyID}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.CompanyID &&
                                            Boolean(formik.errors.CompanyID)
                                        }
                                        helperText={
                                            formik.touched.CompanyID && formik.errors.CompanyID
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Material Damage Excess Rate</FormLabel>
                                    <TextField
                                        id="material_damage_excess_rate"
                                        name="material_damage_excess_rate"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.material_damage_excess_rate}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.material_damage_excess_rate &&
                                            Boolean(formik.errors.material_damage_excess_rate)
                                        }
                                        helperText={
                                            formik.touched.material_damage_excess_rate && formik.errors.material_damage_excess_rate
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Material Damage Excess Minimum</FormLabel>
                                    <TextField
                                        id="material_damage_excess_minimum"
                                        name="material_damage_excess_minimum"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.material_damage_excess_minimum}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.material_damage_excess_minimum &&
                                            Boolean(formik.errors.material_damage_excess_minimum)
                                        }
                                        helperText={
                                            formik.touched.material_damage_excess_minimum && formik.errors.material_damage_excess_minimum
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>PVT Rate</FormLabel>
                                    <TextField
                                        id="pvt_rate"
                                        name="pvt_rate"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.pvt_rate}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.pvt_rate &&
                                            Boolean(formik.errors.pvt_rate)
                                        }
                                        helperText={
                                            formik.touched.pvt_rate && formik.errors.pvt_rate
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>PVT Minimum</FormLabel>
                                    <TextField
                                        id="pvt_minimum"
                                        name="pvt_minimum"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.pvt_minimum}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.pvt_minimum &&
                                            Boolean(formik.errors.pvt_minimum)
                                        }
                                        helperText={
                                            formik.touched.pvt_minimum && formik.errors.pvt_minimum
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='secondary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                        color="error"
                    >
                        Cancle
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Add;