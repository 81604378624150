/* eslint-disable react/prop-types */
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from '@mui/lab';
import { Autocomplete, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, MenuItem, OutlinedInput, Radio, RadioGroup, Rating, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from "yup";
import { apipost } from '../../service/api';
import Palette from '../../theme/palette';

const Add = (props) => {

  const { open, handleClose, contactData, setUserAction } = props
  const [isLoading, setIsLoading] = useState(false);

  const userid = sessionStorage.getItem('user_id');
  const userdata = JSON.parse(sessionStorage.getItem('user'));
  const userDetails = useSelector((state) => state?.userDetails?.data)
  const generateInvoiceNumber = () => {
    let now = String(new Date().getTime());
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < 6; i++) {
      const randomInd = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomInd);
    }
    return `INV${result}${now}`;
  }
  // -----------  validationSchema
  const validationSchema = yup.object({
    CustomerName: yup.string().required("Customer Name is required"),
    kraPin: yup.string().required("KRA Pin is required"),
    phoneNumber: yup.string().matches(/^[0-9]*$/, 'Phone Number is invalid').required('Phone Number is required'),
    email: yup.string().email('Invalid email').required("Email is required"),
    product: yup.string().required("Product is required"),
    unit_cost: yup.string().required("Unit Cost is required"),
    quantity: yup.string().required("Quantity is required"),
    description: yup.string().required("Description is required"),
  });

  // -----------   initialValues
  const initialValues = {
    CustomerName: "",
    kraPin: "",
    phoneNumber: "",
    email: "",
    product: "",
    unit_cost: "",
    quantity: "",
    description: "",
    createdBy: userid,
    agency_id: userdata?.agency_id
  };

  // add Lead api
  const addInvoice = async (values) => {
    setIsLoading(true);
    try {
      const data = values;
      const result = await apipost('api/krainvoice/add', data);
      setUserAction(result);

      if (result && result.status === 201) {
        formik.resetForm();
        handleClose();
      }
    } catch (error) {
      console.error('Error adding lead:', error);
    }
    setIsLoading(false);
  }
  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const invoiceData = {...values, InvoiceNumber: generateInvoiceNumber(),}
      addInvoice(invoiceData)
    },
  });


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Add New Invoice</Typography>
          <Typography>
            <ClearIcon
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              <Typography style={{ marginBottom: "15px" }} variant="h6">
                Basic Customer Information
              </Typography>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Customer Name <span style={{ color: "red" }}>*</span></FormLabel>
                  <TextField
                    id="CustomerName"
                    name="CustomerName"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.CustomerName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.CustomerName &&
                      Boolean(formik.errors.CustomerName)
                    }
                    helperText={
                      formik.touched.CustomerName && formik.errors.CustomerName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Phone Number <span style={{ color: "red" }}>*</span></FormLabel>
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    size='small'
                    fullWidth
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>Email <span style={{ color: "red" }}>*</span></FormLabel>
                  <TextField
                    id="email"
                    name="email"
                    label=""
                    size='small'
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.email &&
                      Boolean(formik.errors.email)
                    }
                    helperText={
                      formik.touched.email && formik.errors.email
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>KRA Pin</FormLabel>
                  <TextField
                    id="kraPin"
                    name="kraPin"
                    size='small'
                    fullWidth
                    value={formik.values.kraPin}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.kraPin &&
                      Boolean(formik.errors.kraPin)
                    }
                    helperText={
                      formik.touched.kraPin && formik.errors.kraPin
                    }
                  />
                </Grid>
              </Grid>
              <Typography
                style={{ marginBottom: "15px", marginTop: "15px" }}
                variant="h6"
              >
                Product Information
                </Typography>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Product / Service Name</FormLabel>
                  <TextField
                    id="product"
                    name="product"
                    size='small'
                    fullWidth
                    value={formik.values.product}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.product &&
                      Boolean(formik.errors.product)
                    }
                    helperText={
                      formik.touched.product && formik.errors.product
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Unit Cost</FormLabel>
                  <TextField
                    id="unit_cost"
                    name="unit_cost"
                    type="number"
                    size='small'
                    fullWidth
                    value={formik.values.unit_cost}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.unit_cost &&
                      Boolean(formik.errors.unit_cost)
                    }
                    helperText={
                      formik.touched.unit_cost && formik.errors.unit_cost
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Quantity</FormLabel>
                  <TextField
                    id="quantity"
                    name="quantity"
                    type="number"
                    size='small'
                    fullWidth
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.quantity &&
                      Boolean(formik.errors.quantity)
                    }
                    helperText={
                      formik.touched.quantity && formik.errors.quantity
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormLabel>Description</FormLabel>
                    <TextField
                      id="description"
                      name="description"
                      label=""
                      multiline
                      rows={5}
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Grid>
              </Grid>
            </DialogContentText>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
            {isLoading ? <CircularProgress size={27} /> : 'Save'}
          </LoadingButton>
          <Button onClick={() => {
            formik.resetForm()
            handleClose()
          }} variant='outlined' color='error'>Cancle</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Add