import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormLabel, Grid, TextField, FormControl, FormControlLabel, FormHelperText, MenuItem, Select, Radio, RadioGroup, } from '@mui/material';
import Palette from '../../theme/palette';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { apipost } from '../../service/api';

const DoubleInsurance = (props) => {

    const { open, handleClose, setUserAction } = props;
    const [isLoading, setIsLoading] = useState(false);

    const userdata = JSON.parse(sessionStorage.getItem('user'));

    // -----------  validationSchema
    const validationSchema = yup.object({
        VehicleRegistrationnumber: yup.string().required("Vehicle Registration Number is required"),
        policystartdate: yup.string().required("Policy Start Date is required"),
        policyenddate: yup.string().required("Policy End Date is required"),
    });

    // -----------   initialValues
    const initialValues = {
        VehicleRegistrationnumber: "",
        policystartdate: "",
        policyenddate: "",
    };

    const doubleInsurance = async (values) => {
        setIsLoading(true)
        try {
            const data = values;
            let result = await apipost('api/dmvic/validatedoubleinsurance', data);
            setUserAction(result);
            if (result && (result.status >= 200 && result.status < 300)) {
                formik.resetForm();
                handleClose();
            } else {
                toast.error(`${result?.response?.data?.message}`)
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            doubleInsurance(values);
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => {
                    formik.resetForm();
                    handleClose();
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Validate Double Insurance</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel>Vehicle Registration Number <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        id="VehicleRegistrationnumber"
                                        name="VehicleRegistrationnumber"
                                        label=""
                                        size='small'
                                        maxRows={10}
                                        fullWidth
                                        value={formik.values.VehicleRegistrationnumber}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.VehicleRegistrationnumber &&
                                            Boolean(formik.errors.VehicleRegistrationnumber)
                                        }
                                        helperText={
                                            formik.touched.VehicleRegistrationnumber && formik.errors.VehicleRegistrationnumber
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Policy Start Date</FormLabel>
                                <TextField
                                    id="policystartdate"
                                    name="policystartdate"
                                    type='date'
                                    size='small'
                                    fullWidth
                                    value={formik.values.policystartdate}
                                    onChange={formik.handleChange}
                                />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Policy End Date</FormLabel>
                                <TextField
                                    id="policyenddate"
                                    name="policyenddate"
                                    type='date'
                                    size='small'
                                    fullWidth
                                    value={formik.values.policyenddate}
                                    onChange={formik.handleChange}
                                />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='secondary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button onClick={() => {
                        formik.resetForm()
                        handleClose()
                    }} variant='outlined' color='error'>Cancle</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DoubleInsurance;