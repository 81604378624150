export const certificateTypes = [
    {label: 'Class A - PSV Unmarked', value: 1},
    {label: 'Type A Bus', value: 6},
    {label: 'Type A Matatu', value: 7},
    {label: 'Type A Taxi', value: 8},
    {label: 'Type B - Commercial Vehicle', value: 2},
    {label: 'Type C - Private Car', value: 3},
    {label: 'Type D - Motor Cycle', value: 4},
    {label: 'Type D - PSV Motor Cycle', value: 9},
    {label: 'Type D - Motor Cycle Commercial', value: 10},
]
// 1. Type C- Motor Private -HDO/0700/558509/2024
// 2. Type B - Motor Commercial-HD0/0800/007371/2024
// 3. Type D - Motor Cycle-HDO/0701/002321/2024
// 4. Type D -  Motor Cycle  PSV-HDO/0810/004991/2024

// 1	Class A - PSV Unmarked
// 6	Type A Bus
// 7	Type A Matatu
// 8	Type A Taxi
// 2   Type B - Commercial Vehicle
// 3   Type C - Private Car
// 4	Type D Motor Cycle
// 9	Type D PSV Motor Cycle
// 10	Type D – Motor Cycle Commercial