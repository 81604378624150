/* eslint-disable no-useless-catch */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiget } from '../../service/api';

export const fetchUnderwriterData = createAsyncThunk('fetchUnderwriterData', async () => {
    try {
        const response = await apiget(`api/underwriter/`);
        return response?.data?.result;
    } catch (error) {
        throw error;
    }
});

const underwriterSlice = createSlice({
    name: 'underwriterDetails',
    initialState: {
        data: [],
        isLoading: false,
        error: "",
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUnderwriterData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUnderwriterData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                state.error = "";
            })
            .addCase(fetchUnderwriterData.rejected, (state, action) => {
                state.isLoading = false;
                state.data = [];
                state.error = action.error.message;
            });
    },
});

export default underwriterSlice.reducer;