/* eslint-disable react/prop-types */
import { Box, Card, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import Palette from '../../theme/palette'
import Addemail from '../../components/email/Addemail'
import { certificateTypes } from "src/_mock/dmvicCertTypes";

const Overview = ({ data, setUserAction, isLoading }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <div>
      {isLoading ? (
        <Card style={{ display: 'flex', justifyContent: 'center', paddingBottom: "70px" }}>
          <span className="loader" />
        </Card>
      ) : (
        <Card style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
          <Box p={3}>
            <Grid container display="flex" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                  <Typography variant="body1">Certificate ID / Policy Number :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{ data?.policynumber ? data?.policynumber : "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                  <Typography variant="body1">Policy Start Date :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>
                    {
                      data?.Commencingdate ? data?.Commencingdate : "--"
                    }
                  </Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                  <Typography variant="body1">Policy Expiry Date :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>
                    {
                      data?.Expiringdate ? data?.Expiringdate : "--"
                    }
                  </Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                  <Typography variant="body1">Policy Holder :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{data?.Policyholder ? data?.Policyholder : "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                  <Typography variant="body1">Policy Holder KRA PIN :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{data?.InsuredPIN ? data?.InsuredPIN : "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                  <Typography variant="body1">Policy Holder Phone Number:</Typography>
                  <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{data?.Phonenumber ? data?.Phonenumber : "--"}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Underwriter Name :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.certificateUnderwriterName ? data?.certificateUnderwriterName : "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Member Company ID :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.MemberCompanyID ? data?.MemberCompanyID : "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Type Of Certificate :</Typography>
                  <Typography variant="body2" color={Palette.primary.main} onClick={handleOpen} style={{ cursor: "pointer" }}>{data?.TypeOfCertificate ? certificateTypes.find((cert) => cert.value === String(data?.TypeOfCertificate))?.label || "--" : "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Type Of Cover :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.Typeofcover ? data?.Typeofcover : "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Certificate Issuance Result :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.success ? "Successful" : "Not Successful"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Certificate Verification Status :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.status || "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Certificate Issuance Response Message :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.response ? (data?.response instanceof Array) ? data?.response.join(', ') : data?.response : "--"}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      )}

    </div>
  )
}

export default Overview
