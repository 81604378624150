import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid';
import moment from "moment";
import { useEffect, useState } from 'react';
import { CiExport } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TableStyle from '../../components/TableStyle';
import Iconify from '../../components/iconify';
import { commonUtils } from '../../utils/utils';
import { apipost } from 'src/service/api';

// ----------------------------------------------------------------------

const CompanyStock = (props) => {

    const { dmvicMemberCompanyID } = props
    const [userAction, setUserAction] = useState(null);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [stocks, setStock] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const fetchStock = async (MemberCompanyID) => {
        setIsLoading(true)
        try {
            const result = await apipost(`api/dmvic/companystock`, {MemberCompanyID: MemberCompanyID});
            if (result && result.status === 200) {
                setStock(result?.data);
            }
        } catch (error) {
            console.error("Error fetching call data:", error);
        }
        setIsLoading(false)
    };

    const columns = [
        {
            field: "CertificateClassificationID",
            headerName: "Certificate ID",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
        },
        {
            field: "ClassificationTitle",
            headerName: "Certificate Classification Title",
            flex: 1,
            cellClassName: "name-column--cell--capitalize",
        },
        {
            field: "Stock",
            headerName: "Amount of Stock",
            flex: 1,
        },
    ];

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };



    useEffect(() => {
        fetchStock(dmvicMemberCompanyID);
    }, [userAction, dmvicMemberCompanyID])

    return (
        <>
                <Stack direction="row" alignItems="center" mb={2} justifyContent={"space-between"}>
                    <Typography variant="h5" >
                        Company stocks
                    </Typography>
                </Stack>
                <TableStyle>
                    <Box width="100%">
                        {isLoading ? (
                            <Card style={{ display: 'flex', justifyContent: 'center', height: "400px" }}>
                                <span className="loader" />
                            </Card>
                        ) : (
                            <Card style={{ height: "400px" }}>
                                <DataGrid
                                    rows={stocks || []}
                                    columns={columns}
                                    components={{
                                        Toolbar: () => (<Box padding={"10px 0"}>
                                            <GridToolbarColumnsButton />
                                            <GridToolbarFilterButton />
                                            <GridToolbarDensitySelector
                                                slotProps={{ tooltip: { title: 'Change density' } }}
                                            />
                                        </Box>)
                                    }}
                                    checkboxSelection
                                    onRowSelectionModelChange={handleSelectionChange}
                                    rowSelectionModel={selectedRowIds}
                                    getRowId={row => row.CertificateClassificationID}
                                />
                            </Card>
                        )}

                    </Box>
                </TableStyle>
        </>
    );
}

export default CompanyStock