/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography, Card } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from 'react-router-dom';
import Add from './add';
import TableStyleTwo from '../TableStyleTwo';
import { fetchUnderwriterData } from 'src/redux/slice/underwriterSlice';


const Show = () => {

  const [openAdd, setOpenAdd] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userAction, setUserAction] = useState(null);
  // open note model
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const { data, isLoading } = useSelector((state) => state?.underwriterDetails) || []

  const columns = [
    {
      field: "underwriterName",
      headerName: "Underwriter Name",
      flex: 1,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/underwriterpremiums/view/${params.row._id}`)
        };

        return (
          <Box onClick={handleFirstNameClick}>
            {params.value}
          </Box>
        );
      }
    },
    {
      field: "CompanyID",
      headerName: "Member Company ID",
      flex: 1,
    },
    {
      field: "material_damage_excess_rate",
      headerName: "Material Damage Rate",
      flex: 1,
      renderCell: (params) => {
        return (parseFloat((Number(params.row?.material_damage_excess_rate) || 0).toFixed(2)).toLocaleString());
      }
    },
    {
      field: "material_damage_excess_minimum",
      headerName: "Material Damage Minimum",
      flex: 1,
      renderCell: (params) => {
        return (parseFloat((Number(params.row?.material_damage_excess_minimum) || 0).toFixed(2)).toLocaleString());
      }
    },
    {
      field: "pvt_rate",
      headerName: "PVT Rate",
      flex: 1,
      renderCell: (params) => {
        return (parseFloat((Number(params.row?.pvt_rate) || 0).toFixed(2)).toLocaleString());
      }
    },
    {
      field: "pvt_minimum",
      headerName: "PVT Minimum",
      flex: 1,
      renderCell: (params) => {
        return (parseFloat((Number(params.row?.pvt_minimum) || 0).toFixed(2)).toLocaleString());
      }
    },
  ];


  useEffect(() => {
    dispatch(fetchUnderwriterData())
  }, [userAction])


  return (
    <div>
      {/* Add email */}
      <Add open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />

      <Box
        style={{ cursor: "pointer" }}
        p={2}
      >
        <Grid container display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Typography variant="h5">Underwriters</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleOpenAdd}
                >
                  Create Underwriter
                </Button>
            </Stack>
          </Stack>
        </Grid>
      </Box>
        <TableStyleTwo>
          <Box width="100%">
            {isLoading ? (
              <Card style={{ display: 'flex', justifyContent: 'center', height: "400px" }}>
                <span className="loader" />
              </Card>
            ) : (
              <Card style={{ height: "400px" }} className='tableWraper'>
              <DataGrid
                rows={data || []}
                columns={columns}
                columnHeaderHeight={40}
                getRowId={row => row._id}

              />
              </Card>
               )}
          </Box>
        </TableStyleTwo>
    </div>
  )
}

export default Show
