import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from '@mui/lab';
import { CircularProgress, FormControl, FormLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import InvoiceTemplate from "./invoiceTemplate";


const PrintInvoice = (props) => {
  const { open, handleClose, invoiceData, setInvoiceData } = props
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()

  // -----------  validationSchema
  const validationSchema = yup.object({
    file: yup.string().required("Image File is required"),
    agencyName: yup.string().required('Agency Name is invalid'),
    agencyKRAPin: yup.string().required('KRA Pin is required'),
    agencyEmail: yup.string().email('Invalid email').required("Email is required"),
    agencyContact: yup.string().matches(/^[0-9]*$/, 'Phone Number is invalid').required('Phone Number is required'),
  });

  // -----------   initialValues
  const initialValues = {
    agencyName: "ANZIANO INSURANCE AGENCY",
    agencyKRAPin: "",
    file: "",
    agencyEmail: "info@anzianoinsuranceagency.com",
    agencyContact: "254700222219",
  };

  const submitDetails = async (values) => {
    setIsLoading(true);
    const templateData = {...values, ...invoiceData}
    const filename = `${invoiceData?.InvoiceNumber}.pdf`;
    setInvoiceData(templateData);
    // await ReactPDF.render(<InvoiceTemplate data={templateData}/>, filename);
    setIsLoading(false);
    formik.resetForm();
    handleClose();
  }
  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      submitDetails(values)
    },
  });


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Print Invoice</Typography>
          <Typography>
            <ClearIcon
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form encType="multipart/form-data">
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Agency Name</FormLabel>
                  <TextField
                    id="agencyName"
                    name="agencyName"
                    size='small'
                    fullWidth
                    value={formik.values.agencyName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.agencyName &&
                      Boolean(formik.errors.agencyName)
                    }
                    helperText={
                      formik.touched.agencyName && formik.errors.agencyName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>KRA Pin</FormLabel>
                  <TextField
                    id="agencyKRAPin"
                    name="agencyKRAPin"
                    size='small'
                    fullWidth
                    value={formik.values.agencyKRAPin}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.agencyKRAPin &&
                      Boolean(formik.errors.agencyKRAPin)
                    }
                    helperText={
                      formik.touched.agencyKRAPin && formik.errors.agencyKRAPin
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Agency Email</FormLabel>
                  <TextField
                    id="agencyEmail"
                    name="agencyEmail"
                    size='small'
                    type="email"
                    fullWidth
                    value={formik.values.agencyEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.agencyEmail &&
                      Boolean(formik.errors.agencyEmail)
                    }
                    helperText={
                      formik.touched.agencyEmail && formik.errors.agencyEmail
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Agency Contact</FormLabel>
                  <TextField
                    id="agencyContact"
                    name="agencyContact"
                    size='small'
                    fullWidth
                    value={formik.values.agencyContact}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.agencyContact &&
                      Boolean(formik.errors.agencyContact)
                    }
                    helperText={
                      formik.touched.agencyContact && formik.errors.agencyContact
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormLabel>Upload Agency Logo<span style={{ color: "red" }}>*</span></FormLabel>
                    <TextField
                        id="file"
                        name="file"
                        size="small"
                        maxRows={10}
                        fullWidth
                        type="file"
                        multiple
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => {
                          if(event.currentTarget.files.length > 0){
                            const file = event.currentTarget.files[0];
                            switch(file.type){
                              case 'image/png':
                                formik.setFieldValue("file", file);
                                break;
                              case 'image/jpg':
                                formik.setFieldValue("file", file);
                                break;
                              case 'image/jpeg':
                                formik.setFieldValue("file", file);
                                break;
                              default:
                            }
                          }
                        }}
                        error={
                            formik.touched.file &&
                            Boolean(formik.errors.file)
                        }
                        helperText={
                            formik.touched.file && formik.errors.file
                        }
                    />
                </Grid>
              </Grid>
            </DialogContentText>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
            {isLoading ? <CircularProgress size={27} /> : 'Submit'}
          </LoadingButton>
          <Button onClick={() => {
            formik.resetForm()
            handleClose()
          }} variant='outlined' color='error'>Cancle</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PrintInvoice;