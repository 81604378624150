import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TableStyle from '../../../components/TableStyle';
import { fetchWAContactData } from '../../../redux/slice/WAcontactSlice';
import { apipost } from '../../../service/api';
import { useDispatch, useSelector } from 'react-redux';
import Palette from "../../../theme/palette";
import SendMessage from '../SendMessage';
import AddIcon from '@mui/icons-material/Add';


// ----------------------------------------------------------------------


const WAMessage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const Contacts = useSelector((state) => state?.WAcontactDetails?.data);
  const [contactData, setContactData] = useState({});
  const [messagesData, setMessagesData] = useState([]);
  const [userAction, setUserAction] = useState(null)
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const [openAdd, setOpenAdd] = useState(false)

  // open add model
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const fetchMessagesdata = async () => {
    setIsLoading(true)
    try {
        const fetchedContact = Contacts.find((contact)=> contact.id === Number(params.id)) || {}
        setContactData(fetchedContact);
        const result = await apipost(`api/whatsapp/contactmessages`, {contact_id: params.id});
        if (result && result.status === 200) {
            setMessagesData(result?.data?.result.reverse());
        }
    } catch (error) {
        console.error("Error fetching SMS data:", error);
    }
    setIsLoading(false)
  };

  useEffect(() => {
    if (params.id) {
      if (Object.keys(Contacts).length === 0)
        dispatch(fetchWAContactData());
      fetchMessagesdata();
    }
  }, [userAction, Contacts, contactData])


  return (
    <>
      {/* Add Lead Model */}
      <SendMessage open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} contactData={contactData}/>

      <Container maxWidth>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" >
            Contact Messages
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleOpenAdd}
              >
                Reply
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
        {isLoading ?
          <></> : 
          <>
          <div>
            <Typography variant="body1">Name :</Typography>
            <Typography variant="body1">Phone :</Typography>
          </div>
          <div>
            <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{contactData?.name ? contactData?.name : "--"}</Typography>
            <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{contactData?.phone ? contactData?.phone : "--"}</Typography>
          </div>
          </>
        }
        </Stack>
        <TableStyle>
          <Box width="100%">
            {isLoading ? (
              <Card style={{ display: 'flex', justifyContent: 'center', height: "600px" }}>
                <span className="loader" />
              </Card>
            ) : (
              <Card style={{ padding: '20px', display: 'flex', flexDirection: 'column', overflow: 'auto', height: "600px", rowGap: '20px' }}>
                  {
                    messagesData.map(
                      (conversation) => {
                        return (conversation.is_message_by_contact === 1 ?
                          (<div style={{alignSelf: 'flex-start', width: '50%', background: '#74CAFF', border: '2px solid black', borderRadius: '12px', padding: '5px'}}>
                            <p style={{overflowWrap: 'anywhere'}}>{conversation.value}</p>
                            <span style={{fontSize: '0.7em'}}>{moment(conversation.created_at).format("DD/MM/YYYY, h:mm a")}</span>
                          </div>) : (<div style={{alignSelf: 'flex-end', width: '50%', background: '#76B0F1', border: '2px solid black', borderRadius: '12px', padding: '5px'}}>
                            <p style={{overflowWrap: 'anywhere'}}>{conversation.value}</p>
                            <span style={{fontSize: '0.7em'}}>{moment(conversation.created_at).format("DD/MM/YYYY, h:mm a")}</span>
                          </div>));
                      }
                    )
                  }
              </Card>
            )}


          </Box>
        </TableStyle>
      </Container>
    </>
  );
}

export default WAMessage