import { Box, Container, Grid, Stack, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Actionbutton from '../Actionbutton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { CustomTabPanel, a11yProps } from '../CustomTabPanel';
import Header from '../Header';
import { apiget, apipost, apidelete } from '../../service/api';
import Moreinformation from './Moreinformation';
import Other from './Other';
import Overview from './Overview';
import CancelModel from './CancelCertificate';
import VerifyLogbook from './VerifyLogbook';
import EditCertificate from './EditSavedCertificate';

const View = () => {
    const [certificateData, setCertificateData] = useState({});
    const [userAction, setUserAction] = useState(null);
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openCancel, setCancel] = useState(false);
    const [openReissue, setReissue] = useState(false);
    const [openEdit, setEdit] = useState(false);
    const params = useParams()
    const navigate = useNavigate();
    // tab
    const handleChange = (event, newValue) => setValue(newValue);
    const handleCloseReissue = () => setReissue(false);
    const handleOpenReissue = () => setReissue(true);
    const handleCloseEdit = () => setEdit(false);
    const handleOpenEdit = () => setEdit(true);
    const handleCloseCancel = () => setCancel(false);
    const handleOpencancel = () => setCancel(true)
    // fetch api
    const fetchCertificatedata = async () => {
        setIsLoading(true)
        try {
            const result = await apiget(`api/dmvic/certificates/view/${params.id}`);
            if (result && result.status === 200) {
                setCertificateData(result?.data?.certificate);
            }
        } catch (error) {
            console.error("Error fetching Certificate data:", error);
        }
        setIsLoading(false)
    };

    const getCertificatePDF = async () => {
        try {
            const result = await apiget(`api/dmvic/getcertificates/${params.id}`);
            setUserAction(result);
            if (result && result.status >= 200 && result.status < 300) {
                window.open(result?.data?.certificateurl,'_blank', 'rel=noopener noreferrer');
            }
        } catch (error) {
            console.error("Error obtaining Certificate:", error);
        }
    }

    const PreviewCertificate = async () => {
        try {
            const result = await apipost(`api/dmvic/previewcertificates/${params.id}`);
            setUserAction(result);
            if (result && result.status >= 200 && result.status < 300) {
                window.open(result?.data?.previewURL,'_blank', 'rel=noopener noreferrer');
            }
        } catch (error) {
            console.error("Error previewing Certificate:", error);
        }
    }

    const VerifyCertificate = async () => {
        try {
            const result = await apipost(`api/dmvic/verifycertificatestatus/${params.id}`);
            setUserAction(result);
        } catch (error) {
            console.error("Error verifying Certificate:", error);
        }
    }

    const validateCertificate = async () => {
        try {
            const result = await apipost(`api/dmvic/validatecertificates/${params.id}`);
            setUserAction(result);
        } catch (error) {
            console.error("Error validating Certificate:", error);
        }
    }
    const issueCertificate = async () => {
        try {
            const result = await apipost(`api/dmvic/issuecertificates/${params.id}`);
            setUserAction(result);
        } catch (error) {
            console.error("Error reissuing Certificate :", error);
        }
    }

    const deleteCertificate = async () => {
        try {
            const result = await apidelete(`api/dmvic/deleteunsuccessfulcertificates/${params.id}`);
            setUserAction(result);
            navigate(`/dashboard/dmvicpayment/view/${certificateData?.payment_id}`);
        } catch (error) {
            console.error("Error deleting Certificate:", error);
        }
    }

    useEffect(() => {
        if (Object.keys(certificateData).length === 0)
            fetchCertificatedata();
    }, [userAction])

    return (
        <div>
            <EditCertificate open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} data={certificateData}/>
            <VerifyLogbook open={openReissue} handleClose={handleCloseReissue} setUserAction={setUserAction}/>
            <CancelModel open={openCancel} handleClose={handleCloseCancel} setUserAction={setUserAction} id={params.id}/>
            <Container maxWidth>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" mb={3} justifyContent={"space-between"} width={"100%"}>
                        <Header
                            title="DMVIC Certificate Details"
                        />
                        {certificateData?.success && certificateData?.CertificateNumber ? 
                            <>
                                <Button variant="contained" onClick={PreviewCertificate}>
                                    Preview
                                </Button>
                                <Button variant="contained" onClick={getCertificatePDF}>
                                    Get PDF
                                </Button>
                                <Button variant="contained" onClick={VerifyCertificate}>
                                    Verify
                                </Button>
                                <Button variant="contained" onClick={validateCertificate}>
                                    Validate
                                </Button>
                                <Button variant="contained" onClick={handleOpencancel}>
                                    Cancel
                                </Button> 
                            </> :
                            <>
                            { certificateData?.IssuanceRequestID ?
                                <Button variant="contained" onClick={handleOpenReissue}>
                                    Verify Issuance
                                </Button> : <></>
                            }
                            { !certificateData?.CertificateNumber ? 
                                <Button variant="contained" onClick={issueCertificate}>
                                    Issue
                                </Button> : <></>
                            }
                                <Button variant="contained" onClick={handleOpenEdit}>
                                    Edit
                                </Button>
                                <Button variant="contained" onClick={deleteCertificate}>
                                    Delete
                                </Button>
                            </>
                    }
                    </Stack>
                </Grid>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "0px" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="OVERVIEW" {...a11yProps(0)} />
                            <Tab label="MORE INFORMATION" {...a11yProps(1)} />
                            <Tab label="OTHER" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Overview data={certificateData} setUserAction={setUserAction} isLoading={isLoading} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Moreinformation data={certificateData} isLoading={isLoading} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Other data={certificateData} isLoading={isLoading} />
                    </CustomTabPanel>
                </Box>

            </Container>
        </div>
    )
}

export default View
