import { useEffect, useState } from 'react';
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, TextField, ListItemText} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { apipost } from '../../service/api';
import { fetchWAtemplateData } from '../../redux/slice/WAtemplateSlice';
import { useDispatch } from 'react-redux';
import Palette from "../../theme/palette";

const Send = (props) => {
    const { open, handleClose, setUserAction, } = props
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()
    const WAContacts = useSelector((state) => state?.WAcontactDetails?.data)
    const waTemplates = useSelector((state) => state?.WAtemplateDetails?.data);
    // -----------  validationSchema
    const validationSchema = yup.object({
        phoneNumber: yup.string().required('Phone Number is invalid'),
        template_name: yup.string().required('Template Name is required'),
        template_language: yup.string().required('The template selected does not have a Template Language'),
    });

    // -----------   initialValues
    const initialValues = {
        phoneNumber: "",
        template_name: "",
        template_language: "",
        components: "",
    };

    // add policy api
    const sendWATemplateMsg = async (values) => {
        setIsLoading(true);
        try {
            const data = {
                phoneNumber: values.phoneNumber,
                template_name: values.template_name,
                template_language: values.template_language,
                components: values.components,
            }
            const result = await apipost('api/whatsapp/sendtemplatemessage', data)

            setUserAction(result)

            if (result && result.status === 201) {
                formik.resetForm();
                handleClose();
            }
        } catch (error) {
            console.error('Error sending whatsapp template message:', error);
        }
        setIsLoading(false);
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            sendWATemplateMsg(values)
        },
    });
    useEffect(() => {
        if (waTemplates?.length === 0) {
            dispatch(fetchWAtemplateData())
        }
    }, [open])
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Send Template Message </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Typography style={{ marginBottom: "15px" }} variant="h6">
                                Whats App Contact Details
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <FormLabel>Phone Number</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        size="small"
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    >
                                        {
                                            WAContacts?.map((contact) =>{ 
                                                return (
                                                    <MenuItem value={contact?.phone}>
                                                        {`${contact?.name}`}
                                                    </MenuItem>
                                                )}
                                            )
                                        }
                                    </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
                                            }
                                        >
                                        {formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <FormLabel>Template Name</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="template_name"
                                        name="template_name"
                                        label=""
                                        size='small'
                                        fullWidth
                                        value={formik.values.template_name}
                                        onChange={(e) => {
                                            formik.setFieldValue('template_name', e.target.value);
                                            const template = waTemplates.find(o => o.name === e.target.value) || {};
                                            formik.setFieldValue("template_language", (template?.language || ""))
                                            formik.setFieldValue("components", JSON.parse((template?.components || '[]').split('\\').join('')))
                                        }}
                                        error={
                                            formik.touched.template_name &&
                                            Boolean(formik.errors.template_name)
                                        }
                                        helperText={
                                            formik.touched.template_name && formik.errors.template_name
                                        }>
                                    {
                                        waTemplates?.map((template) => {
                                        return (
                                            <MenuItem key={template.name} value={template.name}>
                                            {`${template.name}`}
                                            </MenuItem>
                                        );})
                                    }
                                    </Select>
                                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.template_name && formik.errors.template_name}</FormHelperText>
                                </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button onClick={() => {
                        formik.resetForm()
                        handleClose()
                    }} variant='outlined' color='error'>Cancle</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Send;