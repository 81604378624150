import { Box, Container, Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Actionbutton from '../../components/Actionbutton';
import Button from '@mui/material/Button';
import { CustomTabPanel, a11yProps } from '../../components/CustomTabPanel';
import DeleteModel from '../../components/Deletemodle';
import Header from '../../components/Header';
import { apidelete, apiget, apipost } from '../../service/api';
import Add from './Add';
import EditModel from './Edit';
import Other from './Other';
import Overview from './Overview';

const View = () => {
    const [leadData, setLeadData] = useState({});
    const [userAction, setUserAction] = useState(null);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [opendelete, setOpendelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(0);

    const params = useParams()
    const navigate = useNavigate()

    // open add model
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    // open edit model
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const handleOpenDelete = () => setOpendelete(true);
    const handleCloseDelete = () => setOpendelete(false);
    // tab
    const handleChange = (event, newValue) => setValue(newValue);

    const back = () => {
        navigate('/dashboard/app')
    }

    // fetch api
    const fetchData = async () => {
        setIsLoading(true)
        try {
            const result = await apiget(`api/reminder/view/${params.id}`);
            if (result && result.status === 200) {
                setLeadData(result?.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setIsLoading(false)
    };

    // delete api
    const deletedata = async () => {
        await apidelete(`api/reminder/delete/${params.id}`)
        navigate('/dashboard/app')
    }

    useEffect(() => {
        if (params.id) {
            fetchData();
        }
    }, [userAction])


    return (
        <div>
            {/* Add Lead Model */}
            <Add open={openAdd} handleClose={handleCloseAdd} />

            {/* Add Edit Model */}
            <EditModel open={openEdit} handleClose={handleCloseEdit} reminderData={leadData} setUserAction={setUserAction} />

            {/* open Delete Model */}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deletedata} id={params.id} />

            <Container maxWidth>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" mb={3} justifyContent={"space-between"} width={"100%"}>
                        <Header
                            subtitle="Reminder"
                        />
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {/* Action Butoon */}
                            <Actionbutton
                                handleOpen={handleOpenAdd}
                                handleOpenEdit={handleOpenEdit}
                                handleOpenDelete={handleOpenDelete}
                                back={back}
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "0px", overflowX: "auto" }}>
                        <Tabs value={value} onChange={handleChange} style={{ overflowX: "auto" }}>
                            <Tab label="OVERVIEW" {...a11yProps(0)} />
                            <Tab label="OTHER" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Overview data={leadData} setUserAction={setUserAction} isLoading={isLoading} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Other data={leadData} isLoading={isLoading} />
                    </CustomTabPanel>
                </Box>
            </Container>

        </div>
    )
}

export default View
