/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import EmailIcon from "@mui/icons-material/Email";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from 'react-router-dom';
import AddPayment from './AddDmvicPayment';
import TableStyleTwo from '../TableStyleTwo';
import { apipost } from 'src/service/api';
import moment from "moment";


const Payments = ({ rows, toggleVisibilityDmvicPayment, isVisibleDmvicPayment, _id, setUserAction, contactData }) => {

  const [openEmail, setOpenNote] = useState(false);
  const navigate = useNavigate();

  // open note model
  const handleOpenEmail = () => setOpenNote(true);
  const handleCloseEmail = () => setOpenNote(false);
  const resendSTKpush = async (id) => {
    const result = await apipost(`api/dmvic/reinitiatestkPush/${id}`);
    setUserAction(result)
  }

  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 200,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/dmvicpayment/view/${params.row._id}`)
        };

        return (
          <Box onClick={handleFirstNameClick}>
            {params.value}
          </Box>
        );
      }
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
    },
    {
      field: "accountNo",
      headerName: "Account Number",
      width: 200,
    },
    {
      field: "transactionReceiptNumber",
      headerName: "Transaction Code",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
          return (
              <Box>
                  <Stack direction={"row"} spacing={2}>
                      {(params?.row?.status === "Pending Confirmation" && params?.row?.paymentMode === 'Mobile') && (<Button variant='outlined' size='small' color='primary' onClick={() => {resendSTKpush(params?.row?._id)}}>Resend STK</Button>)}
                  </Stack>
              </Box>
          );
      }
    },
    {
      field: 'createdOn',
      headerName: "Create Date",
      width: 270,
      renderCell: (params) => {
        return (
          <>
            {moment(params?.row?.createdOn).format('lll')}
          </>
        );
      }
    },
  ];

  return (
    <div>
      {/* Add email */}
      <AddPayment open={openEmail} handleClose={handleCloseEmail} _id={_id} setUserAction={setUserAction} contactData={contactData}/>

      <Box
        style={{ cursor: "pointer" }}
        p={2}
      >
        <Grid container display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Button
                onClick={toggleVisibilityDmvicPayment}
                color="secondary"
                variant="contained"
                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
              >
                {isVisibleDmvicPayment ? <RemoveIcon /> : <AddIcon />}
              </Button>
              <EmailIcon />
              <Typography variant="h5">Certificate Payments</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
              {isVisibleDmvicPayment && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleOpenEmail}
                >
                  Create Payment
                </Button>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Box>
      {isVisibleDmvicPayment &&
        <TableStyleTwo>
          <Box width="100%" height="30vh">
            <DataGrid
              rows={rows}
              columns={columns}
              columnHeaderHeight={40}
              getRowId={row => row._id}

            />
          </Box>
        </TableStyleTwo>
      }

    </div>
  )
}

export default Payments
