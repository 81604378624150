// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agencyLogo {
    height: 200px;
    width: 200px;
    margin-top: 20px;
    border: 1px solid black;
    border-radius: 5px;
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}`, "",{"version":3,"sources":["webpack://./src/components/quotation/quotation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B","sourcesContent":[".agencyLogo {\n    height: 200px;\n    width: 200px;\n    margin-top: 20px;\n    border: 1px solid black;\n    border-radius: 5px;\n}\n\ntable, th, td {\n    border: 1px solid black;\n    border-collapse: collapse;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
