import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from '@mui/lab';
import { CircularProgress, FormLabel, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from "yup";
import { apiput } from '../../../service/api';
import { useNavigate, useParams } from 'react-router-dom';

const Edit = (props) => {

    const { open, handleClose, setUserAction, data } = props
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams()
    // -----------  validationSchema
    const validationSchema = yup.object({
        minimum_range: yup.string().required("Minimum Range is required"),
        maximum_range: yup.string().required("Maximum Range is required"),
        premium_rate: yup.string().required("Premium Rate is required"),
        premium_minimum: yup.string().required("Premium Minimum is required"),
    });

    // -----------   initialValues
    const initialValues = {
        minimum_range: data?.minimum_range,
        maximum_range: data?.maximum_range,
        premium_rate: data?.premium_rate,
        premium_minimum: data?.premium_minimum,
    };
    // edit Lead api
    const editLead = async (values) => {
        setIsLoading(true)
        try {
            const result = await apiput(`api/underwriter/${params.id}/edit/${data?._id}`, values);
            setUserAction(result);

            if (result && result?.status === 200) {
                handleClose();
            }
        } catch (error) {
            console.error('Error editing lead:', error);
        }
        setIsLoading(false)
    }


    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const leadsData = {
                minimum_range: values.minimum_range,
                maximum_range: values.maximum_range,
                premium_rate: values.premium_rate,
                premium_minimum: values.premium_minimum,
                modifiedOn: new Date(),
            }
            editLead(leadsData)

        },
    });

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={12}>
                                    <FormLabel>Minimum Range</FormLabel>
                                    <TextField
                                        id="minimum_range"
                                        name="minimum_range"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.minimum_range}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.minimum_range &&
                                            Boolean(formik.errors.minimum_range)
                                        }
                                        helperText={
                                            formik.touched.minimum_range && formik.errors.minimum_range
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Maximum Range</FormLabel>
                                    <TextField
                                        id="maximum_range"
                                        name="maximum_range"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.maximum_range}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.maximum_range &&
                                            Boolean(formik.errors.maximum_range)
                                        }
                                        helperText={
                                            formik.touched.maximum_range && formik.errors.maximum_range
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Premium Rate</FormLabel>
                                    <TextField
                                        id="premium_rate"
                                        name="premium_rate"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.premium_rate}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.premium_rate &&
                                            Boolean(formik.errors.premium_rate)
                                        }
                                        helperText={
                                            formik.touched.premium_rate && formik.errors.premium_rate
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Premium Minimum</FormLabel>
                                    <TextField
                                        id="premium_minimum"
                                        name="premium_minimum"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.premium_minimum}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.premium_minimum &&
                                            Boolean(formik.errors.premium_minimum)
                                        }
                                        helperText={
                                            formik.touched.premium_minimum && formik.errors.premium_minimum
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button onClick={() => {
                        formik.resetForm()
                        handleClose()
                    }} variant='outlined' color='error'>Cancle</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Edit