/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography, Card } from '@mui/material'
import React, { useEffect, useState } from 'react';
import EditUnderwriter from '../edit';
import { useNavigate, useParams } from 'react-router-dom';
import { apiget, apidelete } from "../../../service/api";
import Overview from '../overview';
import PolicyNumbers from '../../dmvic/policynumbers/PolicyNumbers';
import PremiumRanges from './premiumranges';
import CompanyStock from '../../dmvic/companyStock';

const Premiums = () => {

  const [openEdit, setOpenEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams()
  const navigate = useNavigate();
  const [isVisiblePolicyNumber, setIsVisiblePolicyNumber] = useState(false);
  const toggleVisibilityPolicyNumber = () => setIsVisiblePolicyNumber(!isVisiblePolicyNumber);
  const [isVisiblePremiumRanges, setIsVisiblePremiumRanges] = useState(false);
  const toggleVisibilityPremiumRanges = () => setIsVisiblePremiumRanges(!isVisiblePremiumRanges);
  // open note model
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const userRole = sessionStorage.getItem("userRole");
  const [underwriter, setUnderwriter] = useState({});
  const [userAction, setUserAction] = useState(null);
  const findUnderwriter = async () => {
    setIsLoading(true);
    try{
      const result = await apiget(`api/underwriter/view/${params.id}`);
      if (result && result.status === 200)
        console.log(result?.data);
        setUnderwriter(result?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false)
  }
  const deleteUnderwriter = async () => {
    const result = await apidelete(`api/underwriter/delete/${params.id}`)
    setUserAction(result)
    navigate('/dashboard/underwriters');
  }


  useEffect(() => {
    findUnderwriter()
  }, [userAction])

  return (
    <div>
      {/* Add email */}
      <EditUnderwriter open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} data={underwriter}/>
      <Box
        style={{ cursor: "pointer" }}
        p={2}
      >
        <Grid container display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Typography variant="h5">Underwriter Premiums</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={handleOpenEdit}
                >
                  Edit Underwriter
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={deleteUnderwriter}
                >
                  Delete Underwriter
                </Button>
            </Stack>
          </Stack>
        </Grid>
      </Box>
        <Overview data={underwriter} isLoading={isLoading}/>
        <CompanyStock dmvicMemberCompanyID={underwriter?.CompanyID || ""}/>
        <Card sx={{ marginTop: "20px" }}>
            <PremiumRanges toggleVisibilityPremiumRanges={toggleVisibilityPremiumRanges} isVisiblePremiumRanges={isVisiblePremiumRanges} setUserAction={setUserAction} rows={underwriter?.premium || []} _id={underwriter?._id}/>
        </Card>
        <Card sx={{ marginTop: "20px" }}>
            <PolicyNumbers toggleVisibilityPolicyNumber={toggleVisibilityPolicyNumber} isVisiblePolicyNumber={isVisiblePolicyNumber} setUserAction={setUserAction} rows={underwriter?.policynumbers || []} _id={underwriter?._id}/>
        </Card>
    </div>
  )
}

export default Premiums
