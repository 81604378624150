/* eslint-disable react/prop-types */
import { Box, Card, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import Palette from '../../theme/palette'
import moment from 'moment'


const Overview = ({ data, isLoading }) => {
  return (
    <div>
      {/* Add Email Model */}
      {isLoading ? (
        <Card style={{ display: 'flex', justifyContent: 'center', paddingBottom: "70px" }}>
          <span className="loader" />
        </Card>
      ) : (
        <Card style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
          <Box p={3}>
            <Grid container display="flex" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                  <Typography variant="body1">Underwriter Name :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{`${data?.underwriterName}`}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                  <Typography variant="body1">Company ID :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{`${data?.CompanyID}`}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Material Damage Rate :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.material_damage_excess_rate ? parseFloat((Number(data?.material_damage_excess_rate) || 0).toFixed(2)).toLocaleString() : "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">Material Damage Minimum :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.material_damage_excess_minimum ? parseFloat((Number(data?.material_damage_excess_minimum) || 0).toFixed(2)).toLocaleString() : "--"}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                  <Typography variant="body1">PVT Rate :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.pvt_rate ? parseFloat((Number(data?.pvt_rate) || 0).toFixed(2)).toLocaleString() : "--"}</Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                  <Typography variant="body1">PVT Minimum :</Typography>
                  <Typography variant="body2" color={Palette.grey[600]}>{data?.pvt_minimum ? parseFloat((Number(data?.pvt_minimum) || 0).toFixed(2)).toLocaleString() : "--"}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                    <Typography variant="body1">Create Date :</Typography>
                    <Typography variant="body2" color={Palette.grey[600]}>
                        {moment(data?.createdOn).format('lll')}
                    </Typography>
                </Grid>
                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                    <Typography variant="body1">Modified Date :</Typography>
                    <Typography variant="body2" color={Palette.grey[600]}>
                        {moment(data?.modifiedOn).format('lll')}
                    </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      )}

    </div>
  )
}

export default Overview
