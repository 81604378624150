import { Box, Button, Stack, Typography, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from "moment";
import { useEffect, useState } from 'react';
import Add from './add';
import { apidelete } from '../../../service/api';
import Edit from './edit';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableStyleTwo from '../../TableStyleTwo';

// ----------------------------------------------------------------------


const PremiumRanges = ({ rows, toggleVisibilityPremiumRanges, isVisiblePremiumRanges, _id, setUserAction }) => {

    const [premiumRange, setPremiumRange] = useState({});
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const userRole = sessionStorage.getItem("userRole");

    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);
    const deleteUnderwriterPremium = async (id) => {
        const result = await apidelete(`api/underwriter/${_id}/delete/${id}`)
        setUserAction(result)
    }
    const columns = [
        {
          field: "minimum_range",
          headerName: "Minimum Range",
          flex: 1,
          renderCell: (params) => {
            return (parseFloat((Number(params.row?.minimum_range) || 0).toFixed(2)).toLocaleString());
          }
        },
        {
          field: "maximum_range",
          headerName: "Maximum Range",
          flex: 1,
          renderCell: (params) => {
            return (parseFloat((Number(params.row?.maximum_range) || 0).toFixed(2)).toLocaleString());
          }
        },
        {
          field: "premium_minimum",
          headerName: "Minimum Premium",
          flex: 1,
          renderCell: (params) => {
            return (parseFloat((Number(params.row?.premium_minimum) || 0).toFixed(2)).toLocaleString());
          }
        },
        {
          field: "premium_rate",
          headerName: "Premium Rate",
          flex: 1,
          renderCell: (params) => {
            return (parseFloat((Number(params.row?.premium_rate) || 0).toFixed(2)).toLocaleString());
          }
        },
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          renderCell: (params) => {
              return (
                  <Box>
                      <Stack direction={"row"} spacing={2}>
                          {userRole !== "user" && (<Button variant='outlined' size='small' color='primary' onClick={() => {setPremiumRange(params.row); handleOpenEdit();}}>Edit</Button>)}
                          {userRole !== "user" && (<Button variant='outlined' size='small' color='error' onClick={() => deleteUnderwriterPremium(params.row._id)}>Delete</Button>)}
                      </Stack>
                  </Box>
              );
          }
        },
      ];

    return (
        <div>
            <Add open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} />
            <Edit open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} data={premiumRange}/>

            <Box style={{ cursor: "pointer" }} p={2}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityPremiumRanges}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisiblePremiumRanges ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AssignmentIcon />
                            <Typography variant="h5">Underwriter Premium Ranges</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisiblePremiumRanges && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"

                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdd}
                                >
                                    Premium Range
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisiblePremiumRanges &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                        />
                    </Box>
                </TableStyleTwo>
            }
        </div>
    );
}

export default PremiumRanges